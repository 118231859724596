import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const LoadingPlaceholder = () => {
  return (
    <Box display="flex">
      <Box mr={10}>
        <Skeleton variant="circle" width={40} height={40} />
      </Box>
      <Box flex={1}>
        <Skeleton />
        <Skeleton width="60%" />
      </Box>
    </Box>
  );
};

import { useState } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { CloudinaryConfig } from "@/components/react-page/plugins/cloudinaryImageUploadPlugin";
import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from "uuid";

export type CloudinaryUploadResponseType = {
  public_id: string;
  width: number;
  height: number;
  url: string;
  secure_url: string;
};

export const useCloudinaryUpload = ({
  cloudName,
  uploadPreset,
  publicFolder,
}: CloudinaryConfig) => {
  // A number from 0 to 100
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const uploadImage = async (
    file: File,
  ): Promise<CloudinaryUploadResponseType> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);
    if (publicFolder) {
      formData.append("public_id", `${publicFolder}/${uuidv4()}`);
    }
    try {
      setUploading(true);
      setUploadProgress(0);
      const res: CloudinaryUploadResponseType = await axios
        .post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData,
          {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const { loaded, total } = progressEvent;
              if (!total) return;
              setUploadProgress(Math.round((loaded / total) * 100));
            },
          },
        )
        .then((r) => r.data);

      return res;
    } catch (e: any) {
      Sentry.withScope((scope) => {
        scope.setTag("action", "UPLOAD_IMAGE");
        Sentry.captureException(e);
      });
      throw e;
    } finally {
      setUploading(false);
    }
  };

  return { uploadImage, uploadProgress, uploading };
};

import { Button } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import clsx from "clsx";
import { useFilterStyles } from "@/components/table/UrlQueryField";
import "react-datepicker/dist/react-datepicker.css";
import { BooleanParam, useQueryParam } from "use-query-params";

export const BooleanQueryField = ({ name }: { name: string }) => {
  const { button, active } = useFilterStyles();
  const [queryValue, setQueryValue] = useQueryParam(name, BooleanParam);

  /**
   * Loops through the filter state in the order of null -> true -> false -> null
   */
  const loopState = () => {
    if (queryValue === true) {
      setQueryValue(false);
      return;
    }
    if (queryValue === false) {
      setQueryValue(null);
      return;
    }
    setQueryValue(true);
  };
  const icon =
    queryValue === true ? <Check /> : queryValue === false ? <Close /> : null;
  return (
    <Button
      endIcon={icon}
      onClick={loopState}
      className={clsx(button, {
        [active]: queryValue !== null,
      })}
      variant="outlined"
      disableRipple
      disableElevation
    >
      {name}
    </Button>
  );
};

import { Box, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { EntityTable } from "@/components/table";
import { CmsMutationAction } from "@/hooks/cms";
import { useProject } from "@/contexts";
import { useEntityUI } from "@/contexts/entityUI";

export const EntityTablePageContent = ({ entity }: { entity: string }) => {
  const { project } = useProject();
  const disabledCreation = (project?.disabledEntityCreations ?? []).includes(
    entity
  );

  const { entityActionHandler } = useEntityUI();

  return (
    <>
      {!disabledCreation && (
        <Box ml="auto" my={2}>
          <Button
            onClick={() =>
              entityActionHandler?.({
                entity,
                action: CmsMutationAction.Create,
              })
            }
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            New {entity}
          </Button>
        </Box>
      )}

      <EntityTable entity={entity} />
    </>
  );
};

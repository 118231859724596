import { Cloudinary } from "@cloudinary/url-gen";
import { format } from "@cloudinary/url-gen/actions/delivery";
import { fill, limitFit } from "@cloudinary/url-gen/actions/resize";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
//@ts-ignore
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { Close as CloseIcon, Image as ImageIcon } from "@material-ui/icons";
import {
  CellPlugin,
  CellPluginComponentProps,
  CellPluginCustomControlsComonent,
} from "@react-page/editor";
import axios, { AxiosProgressEvent } from "axios";
import React, { createContext, useContext, useState } from "react";

export type CloudinaryConfig = {
  cloudName: string;
  uploadPreset: string;
  publicFolder?: string;
};

type ImagePluginMode = "upload" | "url";
interface ImageUploadPluginData {
  alt?: string;
  mode: ImagePluginMode;
  imageId?: string;
  url?: string;
}

export interface UploadData extends ImageUploadPluginData {
  mode: "upload";
  imageId?: string;
  url?: never;
}

export interface UrlData extends ImageUploadPluginData {
  mode: "url";
  imageId?: never;
  url?: string;
}

export const CloudinaryConfigContext = createContext<CloudinaryConfig>({
  cloudName: "demo",
  uploadPreset: "docs_upload_example_us_preset",
});

const ImageUploadControls: CellPluginCustomControlsComonent<
  UploadData | UrlData
> = (props) => {
  const { data, onChange } = props;

  return (
    <>
      <Tabs
        value={data.mode}
        onChange={(_, value: ImagePluginMode) =>
          onChange({ ...data, mode: value })
        }
      >
        <Tab label="Upload" value="upload" />
        <Tab label="Url" value="url" />
      </Tabs>

      <Box p={1}>
        <Box mb={2}>
          <TextField
            label="Image caption"
            value={data.alt ?? ""}
            onChange={(e) => onChange({ ...data, alt: e.target.value })}
          />
        </Box>
        {data.mode === "upload" ? (
          <UploadTab {...(props as any)} />
        ) : (
          <UrlTab {...(props as any)} />
        )}
      </Box>
    </>
  );
};

const UploadTab = ({
  data,
  onChange,
}: CellPluginComponentProps<UploadData>) => {
  const { cloudName, uploadPreset } = useContext(CloudinaryConfigContext);

  // Show a circular progress while image upload
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);
    try {
      setUploading(true);
      setUploadProgress(0);
      const public_id: string = await axios
        .post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData,
          {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const { loaded, total } = progressEvent;
              if (!total) return;
              setUploadProgress(Math.round((loaded / total) * 100));
            },
          },
        )
        .then((r) => r.data.public_id);

      onChange({ ...data, imageId: public_id } as UploadData);
    } catch (e) {
      // @todo: handler error
    } finally {
      setUploading(false);
    }
  };

  const cldImg =
    data.imageId &&
    new Cloudinary({ cloud: { cloudName } })
      .image(data.imageId)
      .resize(fill().height(150));

  return (
    <>
      <Box display="flex" alignItems="center">
        <input
          disabled={uploading}
          accept="image/*"
          hidden
          id="file-upload-button"
          type="file"
          onChange={(e) =>
            e.target.files?.[0] && handleImageUpload(e.target.files?.[0])
          }
        />
        <label htmlFor="file-upload-button">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={uploading}
          >
            Upload image
          </Button>
        </label>
        {uploading && (
          <Box ml={2}>
            <CircularProgress
              value={uploadProgress}
              variant="determinate"
              size={24}
            />
          </Box>
        )}
      </Box>
      {cldImg && (
        <Box mt={2} display="flex" alignItems="center">
          <AdvancedImage
            cldImg={cldImg}
            plugins={[lazyload(), placeholder("blur")]}
          />

          <Box ml={2}>
            <IconButton
              onClick={() => onChange({ ...data, imageId: undefined })}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

const UrlTab = ({ data, onChange }: CellPluginComponentProps<UrlData>) => {
  return (
    <>
      <TextField
        label="Image url"
        value={data.url ?? ""}
        onChange={(e) => onChange({ ...data, url: e.target.value } as UrlData)}
      />
    </>
  );
};

export const cloudinaryImageUploadPlugin: CellPlugin<UploadData | UrlData> = {
  cellStyle: {
    display: "block",
  },

  Renderer: ({ data, isEditMode }) => {
    const { cloudName } = useContext(CloudinaryConfigContext);

    const cldImg =
      data.imageId &&
      new Cloudinary({ cloud: { cloudName } })
        .image(data.imageId)
        .resize(limitFit(2000, 2000))
        .delivery(format(auto()));

    const urlImg = (data.mode === "url" && data.url) || null;

    return (
      <Box id="test">
        {cldImg ? (
          <Box width="100%">
            <AdvancedImage
              width="100%"
              cldImg={cldImg}
              plugins={[lazyload(), placeholder("blur")]}
              alt={data.alt}
            />
          </Box>
        ) : urlImg ? (
          <Box width="100%">
            <img src={urlImg} alt={data.alt} />
          </Box>
        ) : isEditMode ? (
          <Box display="flex" justifyContent="center" py={10}>
            <ImageIcon style={{ fontSize: 128 }} color="disabled" />
          </Box>
        ) : null}
        {/* Should not show placeholder image when preview mode */}
      </Box>
    );
  },
  controls: {
    type: "custom",
    Component: ImageUploadControls,
  },
  id: "cloudinary-image-upload",
  title: "Image URL or upload",
  description: "Upload to cloudinary or specify a url",
  version: 1,
  createInitialData: () => ({ mode: "upload", scale: 1, verticalAlign: "top" }),
};

import { isEnumType, isScalarType } from "graphql";
import { InputObjectFieldTypedef } from "@/helpers/graphqlDocgen";
import * as yup from "yup";

export const getYupTypeFromFieldTypedef = (
  typedef: InputObjectFieldTypedef
): yup.AnySchema | undefined => {
  if (isScalarType(typedef.type)) {
    if (["ID", "String"].includes(typedef.type.name)) {
      return yup.string();
    }
    if (/^Cms.+ID$/.test(typedef.type.name)) {
      return yup.string();
    }
    if (["Int", "Float"].includes(typedef.type.name)) {
      return yup.number();
    }
    if (typedef.type.name === "Boolean") {
      return yup.bool();
    }
    // if (fieldType.name === "Date") {
    //   return yup.date();
    // }
  }

  if (isEnumType(typedef.type)) {
    const enumValues = typedef.type.getValues().map(({ value }) => value);

    return yup.string().oneOf(enumValues);
  }
  return undefined;
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useDialogStyles } from "@/components/form";
import { Close as CloseIcon } from "@material-ui/icons";
import { ReactNode, useEffect } from "react";
import { FormProvider, useForm, UseFormProps } from "react-hook-form";
import { useLeavePageConfirm } from "@/hooks/useLeavePageConfirm";

type FormDialogBaseProps = {
  onClose?: () => void;
  open?: boolean;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  onSubmit?: (data: any) => Promise<void> | void;
  hookFormProps: UseFormProps;
};

export const FormDialogBase = ({
  onClose,
  open = false,
  title = "Dialog",
  content = <></>,
  onSubmit = async (data) => console.log(data),
  actions = <></>,
  hookFormProps,
}: FormDialogBaseProps) => {
  const classes = useDialogStyles();
  const hookForm = useForm({ ...hookFormProps });
  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isDirty, isSubmitted },
  } = hookForm;

  useLeavePageConfirm(isDirty && !isSubmitted);

  const handleFormSubmit = handleSubmit(
    async (data) => {
      await onSubmit(data);
      onClose?.();
    },
    (errors) => {
      console.error(errors);
    },
  );

  const handleClose = () => {
    if (
      Object.values(dirtyFields).length === 0 ||
      (Object.values(dirtyFields).length > 0 &&
        window.confirm(
          "You have unsaved changes. Do you want to close and discard the changes?",
        ))
    ) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <FormProvider {...hookForm}>
      <Dialog
        disableEnforceFocus
        fullScreen
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
        // When we have react-page editor inside a dialog,
        // the dialog (zIndex=1400) will be on top of the react-page sidebar drawer (zIndex=1200)
        // We need to make sure the dialog is below the drawer
        style={{ zIndex: 1199 }}
        PaperProps={{ component: "form", onSubmit: handleFormSubmit }}
      >
        {open && (
          <>
            <DialogTitle disableTypography className={classes.title}>
              <Typography variant="h6">{title}</Typography>
              {onClose && (
                <IconButton
                  aria-label="close"
                  classes={{ root: classes.closeButton }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent className={classes.overflowVisible} dividers>
              {content}
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
          </>
        )}
      </Dialog>
    </FormProvider>
  );
};

import { useGqlDocumentsContext } from "@/contexts";
import {
  QueryWithInputObjectMap,
  MutationWithInputObjectMap,
} from "@/helpers/graphqlDocgen";

export enum CmsMutationAction {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export function useCmsQueryAndInput<IsMany extends boolean>({
  entity,
  isMany,
}: {
  entity: string;
  isMany: IsMany;
}) {
  const queryName = `cmsGet${isMany ? "Many" : "One"}${entity}`;
  const { queries } = useGqlDocumentsContext();
  const queryWithInputObject = queries[queryName] as
    | QueryWithInputObjectMap<IsMany>
    | undefined;

  return {
    query: queryWithInputObject?.query,
    inputObjectMap: queryWithInputObject?.inputObjectMap,
  };
}

export function useCmsMutationAndInput<IsMany extends boolean>({
  action,
  entity,
  isMany,
}: {
  action: CmsMutationAction;
  entity: string;
  isMany: IsMany;
}): Partial<MutationWithInputObjectMap<IsMany>> {
  const mutationName = `cms${action}${isMany ? "Many" : "One"}${entity}`;
  const { mutations } = useGqlDocumentsContext();
  const mutationWithInputObject = mutations[mutationName] as
    | MutationWithInputObjectMap<IsMany>
    | undefined;

  return {
    mutation: mutationWithInputObject?.mutation,
    inputObjectMap: mutationWithInputObject?.inputObjectMap,
  };
}

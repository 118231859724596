import { Badge } from "@/components/tailwind/common/Badge";
import {
  CmsKolJobsPaymentLogPendingCountDocument,
  CmsKolJobsPaymentLogPendingCountQuery,
  CmsKolJobsPaymentLogPendingCountQueryVariables,
} from "@/features/koljobs/generated/generated-hooks";
import { useGqlClient } from "@/hooks/useGqlClient";

export const PendingPaymentNotification = () => {
  const { useGqlQuery } = useGqlClient();
  const { data: { cmsKolJobsPaymentLogCount } = {} } = useGqlQuery<
    CmsKolJobsPaymentLogPendingCountQuery,
    CmsKolJobsPaymentLogPendingCountQueryVariables
  >({
    gqlDocument: CmsKolJobsPaymentLogPendingCountDocument,
    queryKey: ["CmsKolJobsPaymentLogPendingCount"],
  });

  if (!cmsKolJobsPaymentLogCount) return null;

  return (
    <Badge name={cmsKolJobsPaymentLogCount.toString()} variant="inactive" />
  );
};

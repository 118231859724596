import { TextFields } from "@material-ui/icons";
import { pluginFactories } from "@react-page/plugins-slate";
import React from "react";

const SMALLSCREEN_SIZE = 600;

type FontSizeSlatePluginProps = {
  fontSizeValue?: number;
  fontSizeUnit?: "rem" | "px";
  fontSize?: "small" | "medium" | "large" | "xlarge";
};

export const fontSizeSlatePlugin = pluginFactories.createComponentPlugin<FontSizeSlatePluginProps>(
  {
    addHoverButton: true,
    addToolbarButton: true,
    type: "SetFontSize",
    object: "mark",
    icon: <TextFields />,
    label: "Font Size",
    Component: "span",
    getStyle: ({ fontSizeUnit, fontSizeValue, fontSize }) => {
      const isSmallScreen =
        typeof window !== "undefined" && window.innerWidth < SMALLSCREEN_SIZE;

      if (fontSize === "small") {
        if (isSmallScreen) {
          return { fontSize: "0.75rem", lineHeight: "1.125rem" };
        }
        return { fontSize: "0.875rem", lineHeight: "1.25rem" };
      }
      if (fontSize === "medium") {
        if (isSmallScreen) {
          return { fontSize: "0.875rem", lineHeight: "1.25rem" };
        }
        return { fontSize: "1rem", lineHeight: "1.5rem" };
      }
      if (fontSize === "large") {
        if (isSmallScreen) {
          return { fontSize: "1rem", lineHeight: "1.5rem" };
        }
        return { fontSize: "1.125rem", lineHeight: "1.75rem" };
      }
      if (fontSize === "xlarge") {
        if (isSmallScreen) {
          return { fontSize: "1.25rem", lineHeight: "1.75rem" };
        }
        return { fontSize: "1.5rem", lineHeight: "2rem" };
      }

      if (fontSizeUnit && fontSizeValue) {
        if (fontSizeUnit === "rem" && !isSmallScreen) {
          return {
            fontSize: `${(fontSizeValue ?? 1) + 0.125}${fontSizeUnit}`,
          };
        }

        return {
          fontSize: `${fontSizeValue}${fontSizeUnit}`,
        };
      }

      return {};
    },
    controls: {
      type: "autoform",
      schema: {
        type: "object",
        required: ["fontSize"],
        properties: {
          fontSize: {
            type: "string",
            default: "medium",
            uniforms: { allowedValues: ["small", "medium", "large", "xlarge"] },
          },
        },
      },
    },
  }
);

import getConfig from "next/config";

type ImageCompression = Record<"compressionRatio" | "convertSize", number>;

type Env = {
  domain: string;
  isLocal: boolean;
  isPreview: boolean;
  isProd: boolean;
  vercelEnv: "production" | "preview" | "local";
};

type Project = {
  version: string;
  siteName: string;
  projectSlug: string;
};

export type Config = {
  isProd: boolean;
  domain: string;
  isPreview: boolean;
  isLocal: boolean;
  vercelEnv: "production" | "preview" | "local";
  siteName: string;
  projectSlug: string;
  env: Env;
  project: Project;
  features: {
    princess: {
      voice: {
        maxDurationInSec: number;
        filename: string;
      };
      adminRemark: {
        maxImagesCount: number;
      };
    };
  };
  table: {
    itemsPerFetch: number;
    pagination: {
      itemsPerPage: number;
    };
  };
  image: ImageCompression;
  imageUpload: {
    maxUploadSizeInMb: number;
  };
  cmsAuthorizationKey: string;
  service: {
    cloudinary: {
      cloudName: string;
      uploadEndpoint: string;
      princess: {
        uploadPreset: string;
      };
    };
    firebase: {
      apiKey: string;
      authDomain: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
    };
    etherscan: {
      rinkeby: string;
      mainnet: string;
    };
    ethereum: {
      contractAddress: {
        eth721: string;
      };
    };
  };
};

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

export const getPublicConfig = (): Config => publicRuntimeConfig ?? {};

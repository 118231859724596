import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useLabelStyles } from "@/components/input/styles";
import { TextInput } from "@/components/input/TextInput";
import { FormFieldProps } from "@/components/input/types";
import { useProject } from "@/contexts";
import { useNotificationContext } from "@/contexts/notification";
import { ImageUploadType } from "@/features/bora/generated/generated-hooks";
import { useCloudinaryUpload } from "@/hooks/useImageUpload";
import { CloudinaryConfig } from "@/components/react-page/plugins/cloudinaryImageUploadPlugin";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  CloudinaryContext,
  Image,
  Placeholder,
  Transformation,
} from "cloudinary-react";

import { useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useDebounce } from "react-use";

interface ImageUploadInputProps extends FormFieldProps {
  cloudName?: string;
  uploadPreset?: string;
}

const useStyles = makeStyles((theme) => ({
  previewImgContainer: {
    height: 200,
    "& > img": {
      maxWidth: "100%",
      height: "100%",
      maxHeight: "100%",
    },
  },
}));

export const ImageUploadInput = ({
  name,
  label,
  required,
  disabled,
}: ImageUploadInputProps) => {
  const classes = useStyles();
  const labelClasses = useLabelStyles();

  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const { uploadType, cloudName, url } = value;

  // Use a debounce to prevent excessive src changes
  // renders a loading indicator when user is typing
  const [previewUrl, setPreviewUrl] = useState("");
  const [isPreviewReady] = useDebounce(() => setPreviewUrl(url), 500, [url]);

  const { project } = useProject();
  const cloudinaryConfig = project?.cloudinaryConfig;

  const handleUploadTypeChange = (type: ImageUploadType) => {
    onChange({ ...value, uploadType: type });
  };

  const handleCloudinaryUpload = (publicId: string) => {
    onChange({ ...value, publicId, cloudName });
  };

  return (
    <>
      <FormControl fullWidth margin="normal" disabled={disabled}>
        <FormLabel classes={{ root: labelClasses.root }}>{label}</FormLabel>
        <RadioGroup
          row
          value={uploadType}
          onChange={(_, value) =>
            handleUploadTypeChange(value as ImageUploadType)
          }
        >
          {cloudinaryConfig && (
            <FormControlLabel
              value={ImageUploadType.Cloudinary}
              control={<Radio />}
              label={ImageUploadType.Cloudinary}
            />
          )}
          <FormControlLabel
            value={ImageUploadType.Url}
            control={<Radio />}
            label={ImageUploadType.Url}
          />
        </RadioGroup>
        <TextInput
          name={`${name}.alt`}
          label={`${label} Caption`}
          disabled={disabled}
        />
        {uploadType === ImageUploadType.Cloudinary && cloudinaryConfig && (
          <>
            <CloudinaryInput
              disabled={disabled}
              config={cloudinaryConfig}
              onImageUpload={handleCloudinaryUpload}
            />
            {value.publicId && (
              <div className={classes.previewImgContainer}>
                <CloudinaryContext
                  cloudName={value.cloudName ?? cloudinaryConfig.cloudName}
                >
                  <Image public-id={value.publicId} loading="lazy" height="200">
                    <Transformation
                      height="200"
                      fetchFormat="auto"
                      crop="fill"
                    />
                    <Placeholder type="blur" />
                  </Image>
                </CloudinaryContext>
              </div>
            )}
          </>
        )}
        {uploadType === ImageUploadType.Url && (
          <>
            <TextInput
              name={`${name}.url`}
              label={`${label} URL`}
              disabled={disabled}
            />

            {previewUrl && (
              <div className={classes.previewImgContainer}>
                {isPreviewReady() ? (
                  <img src={previewUrl} alt="" />
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </>
        )}
      </FormControl>
    </>
  );
};

interface CloudinaryInputProps {
  config: CloudinaryConfig;
  onImageUpload: (publicId: string) => void;
  disabled?: boolean;
}

const CloudinaryInput = ({
  config,
  onImageUpload,
  disabled,
}: CloudinaryInputProps) => {
  const { uploadImage, uploading, uploadProgress } = useCloudinaryUpload(
    config
  );
  const { handleError } = useErrorHandling();
  const { openNotification } = useNotificationContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <>
      <Box display="flex" alignItems="center">
        <input
          ref={inputRef}
          disabled={disabled || uploading}
          accept="image/*"
          hidden
          type="file"
          onChange={async (e) => {
            if (e.target.files?.[0]) {
              try {
                const { public_id } = await uploadImage(e.target.files?.[0]);
                onImageUpload(public_id);
              } catch (e: any) {
                openNotification({
                  message: `Error occured. ${e.message ?? ""}`,
                  variant: "danger",
                });

                handleError(e);
              }
            }
          }}
        />

        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={uploading}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Upload image
        </Button>
        {uploading && (
          <Box ml={2}>
            <CircularProgress
              value={uploadProgress}
              variant="determinate"
              size={24}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

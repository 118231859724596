import {
  GraphQLInputFieldMap,
  GraphQLInputObjectType,
  GraphQLInputType,
} from "graphql/type";
import { InputObjectFieldTypedefMap } from "./graphqlDocgen/types";

export const getArgTypeNameFromInputObject = (
  rootObject: (InputObjectFieldTypedefMap | GraphQLInputFieldMap) & {
    name?: string;
  },
  path: string
): string => {
  const keys = path.split(".");
  const [root, nextKey] = keys;
  const argTypeName =
    (rootObject[root]?.type as GraphQLInputType & { name?: string })?.name ??
    "";

  const isObject = argTypeName.includes("WhereInput");
  if (!isObject) return argTypeName;
  if (!nextKey) return "";

  const nextRootObject = (rootObject[root]
    .type as GraphQLInputObjectType).getFields();
  return getArgTypeNameFromInputObject(
    nextRootObject as any,
    keys.slice(1).join(".")
  );
};

import { CmsMutationAction } from "@/hooks/cms";

const RELATION_INPUT_TYPE_REGEXP = /(?<entityName>.*?)(?<action>Create|Update)(Nested)?(?<oneOrMany>One|Many)?(.*)Input/;

/**
 * Get the input meta data from the type name
 */
export const getInputTypeMeta = (
  typeName: string
): { entityName?: string; isMulti: boolean; action: CmsMutationAction } => {
  const relationMatch = typeName.match(RELATION_INPUT_TYPE_REGEXP);
  const { entityName, oneOrMany, action } = relationMatch?.groups ?? {};

  return {
    entityName,
    isMulti: oneOrMany === "Many",
    action: action as CmsMutationAction,
  };
};

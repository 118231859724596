import {
  DocumentNode,
  getNamedType,
  GraphQLField,
  isInputObjectType,
} from "graphql";
import { getInputObjectFieldTypedefs } from "@/helpers/graphqlDocgen/getInputObjectFieldTypedefs";
import {
  InputObjectMap,
  MutationWithInputObjectMap,
  QueryWithInputObjectMap,
} from "@/helpers/graphqlDocgen/types";
import { getSelectionSetFromOutputType } from "./getSelectionSetFromOutputType";
import { getVariableDefinitionTypeFromArg } from "./getVariableDefinitionTypeFromArg";

/**
 * Generate a document for a query/mutation field.
 *
 * Overloading so that the correct output type will be specified but the operation
 */
export function fieldToDocumentWithArgs(options: {
  field: GraphQLField<unknown, unknown>;
  operation: "query";
}): QueryWithInputObjectMap;

export function fieldToDocumentWithArgs(options: {
  field: GraphQLField<unknown, unknown>;
  operation: "mutation";
}): MutationWithInputObjectMap;

export function fieldToDocumentWithArgs(options: {
  field: GraphQLField<unknown, unknown>;
  operation: "query" | "mutation";
}): QueryWithInputObjectMap | MutationWithInputObjectMap {
  const { field, operation } = options;
  const { args, name, type } = field;

  const inputObjectMap = args.reduce((obj, arg) => {
    const type = getNamedType(arg.type);
    if (isInputObjectType(type)) {
      return {
        ...obj,
        [arg.name]: getInputObjectFieldTypedefs(type),
      };
    }
    return obj;
  }, {} as InputObjectMap);

  const documentNode: DocumentNode = {
    kind: "Document",
    definitions: [
      {
        kind: "OperationDefinition",
        // query/mutation name(...) {...}
        operation, // query / mutation
        name: { kind: "Name", value: name },

        // ($where: cmsUserWhereOneInput!, ...)
        variableDefinitions: args.map((arg) => ({
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: arg.name },
          },
          type: getVariableDefinitionTypeFromArg(arg.type),
        })),

        // query / mutation field name
        // e.g. cmsGetOneUser(where: $where) { ... }
        selectionSet: {
          kind: "SelectionSet",
          selections: [
            {
              kind: "Field",
              name: { kind: "Name", value: name },
              // We want to expose the query field to the client query document,
              // so we map all arguments to variables
              // argName: $variableName - (argName and variableName are the same)
              arguments: args.map((arg) => ({
                kind: "Argument",
                name: { kind: "Name", value: arg.name },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: arg.name },
                },
              })),
              selectionSet: getSelectionSetFromOutputType(type),
            },
          ],
        },
      },
    ],
  };

  return {
    inputObjectMap,
    // Separate spreading for better typing and maintainability
    ...(operation === "query"
      ? { query: documentNode }
      : { mutation: documentNode }),
  };
}

import {
  CalendarIcon,
  CreditCardIcon,
  DocumentTextIcon,
  OfficeBuildingIcon,
  UserIcon,
  VideoCameraIcon,
  FlagIcon,
} from "@heroicons/react/solid";

import { CrownIcon } from "@/features/koljobs/businessSubscription/components/CrownIcon";
import { ProcessingJobReportNotification } from "@/features/koljobs/jobReport/components/ProcessingJobReportNotification";
import { PendingPaymentNotification } from "@/features/koljobs/paymentLog/components/PendingPaymentNotification";
import { ProjectSettings, ProjectSlug } from "@/settings/types";

export const koljobs: ProjectSettings = {
  name: "KOL Jobs",
  slug: ProjectSlug.Koljobs,
  iconUrl: "/images/icon/project-icon-koljobs.png",
  avatarUrl: "/images/icon/project-avatar-koljobs.png",
  graphqlEndpoint: {
    production: "/api/koljobs",
    preview: "/api/koljobs",
    local: "/api/koljobs",
  },
  cmsBasePathRegex: {
    production: "cms.koljobs.com",
    preview: "cms-preview.koljobs.com",
    local: "cms-local.koljobs.com|cms-local.koljobs.com:3001",
  },
  webEndpoint: {
    production: "https://koljobs.com",
    preview: "https://preview.koljobs.com",
    local: "http://localhost:3078",
  },
  allowEmailRegex: {
    production:
      "kalunxpppp@gmail.com|vina@wemakeapp.net|george@wemakeapp.net|jacky@wemakeapp.net|samuel@wemakeapp.net|madavid3344@gmail.com|andrew@wemakeapp.net|horrychy@gmail.com",
    preview: "@wemakeapp.net",
    local: "@wemakeapp.net",
  },
  denyIntrospection: true,
  cloudinaryConfig: {
    cloudName: "ddzcypoou",
    uploadPreset: "naaifari",
  },
  sidebarCollections: [
    {
      name: "商戶",
      url: "/table/business?user_status=ACTIVE",
      icon: OfficeBuildingIcon,
    },
    {
      name: "KOL",
      url: "/table/model?user_status=ACTIVE",
      icon: UserIcon,
    },
    {
      name: "通告",
      url: "/table/job",
      icon: DocumentTextIcon,
    },
    {
      name: "舉報",
      url: "/table/jobReport",
      icon: FlagIcon,
      notification: ProcessingJobReportNotification,
    },
    {
      name: "視訊通話",
      url: "/table/videoAppointment",
      icon: VideoCameraIcon,
    },
    {
      name: "商戶帳單紀錄",
      url: "/table/paymentLog",
      icon: CreditCardIcon,
      notification: PendingPaymentNotification,
    },
    {
      name: "商戶訂閱紀錄",
      url: "/table/businessSubscription",
      icon: CrownIcon,
    },
    {
      name: "訂閱計劃",
      url: "/table/subscriptionPlan",
      icon: CalendarIcon,
    },
  ],
};

import { createContext, FC, useContext, useState } from "react";

import {
  Notification,
  NotificationProps,
} from "@/components/tailwind/common/Notification";

type NotifcationArgs = Pick<NotificationProps, "message" | "variant">;

type NotificationContextProps = {
  openNotification: (args: NotifcationArgs) => void;
  closeNotification: () => void;
};

const NotificationContext = createContext<NotificationContextProps>({
  openNotification: () => {},
  closeNotification: () => {},
});

export const NotificationProvider: FC = ({ children }) => {
  const [notificationProps, setNotificationProps] = useState({ open: false });

  const openNotification = (args: NotifcationArgs) => {
    setNotificationProps({ ...args, open: true });
  };

  const closeNotification = () => {
    setNotificationProps((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider
      value={{ openNotification, closeNotification }}
    >
      {children}
      <Notification {...notificationProps} />
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

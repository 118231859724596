import {
  getNamedType,
  GraphQLOutputType,
  isLeafType,
  isObjectType,
  isUnionType,
  SelectionNode,
  SelectionSetNode,
} from "graphql";

const MAX_QUERY_DEPTH = 4;
/**
 * Read field definitions from Graphql types and output selection fields.
 *
 * Prevents infinite loop by setting max depth `MAX_QUERY_DEPTH`
 *
 * @see SelectionSetNode
 */
export function getSelectionSetFromOutputType(
  graphqlType: GraphQLOutputType,
  depth = 0 // Current depth
): SelectionSetNode | undefined {
  if (depth >= MAX_QUERY_DEPTH) {
    return undefined;
  }

  const unwrappedType = getNamedType(graphqlType);

  if (!isObjectType(unwrappedType) && !isUnionType(unwrappedType)) {
    return undefined;
  }

  const selections: SelectionNode[] = [];

  if (isUnionType(unwrappedType)) {
    const unionTypes = unwrappedType.getTypes();
    unionTypes.forEach((unionType) => {
      const unwrappedFieldType = getNamedType(unionType);
      if (isObjectType(unwrappedFieldType)) {
        const selectionSet = getSelectionSetFromOutputType(
          unwrappedFieldType,
          depth + 1
        );
        if (selectionSet) {
          selections.push({
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: unionType.name,
              },
            },
            selectionSet,
          });
        }
      }
    });
  }

  if (isObjectType(unwrappedType)) {
    const fields = unwrappedType.getFields();

    Object.entries(fields).forEach(([fieldName, fieldType]) => {
      const unwrappedFieldType = getNamedType(fieldType.type);

      // We only process Scalar, Enum and Object type at this moment, ignoring other GraphQLType e.g. UnionType InterfaceType
      // Always select scalars and enums. LeafType = Scalar | Enum
      if (isLeafType(unwrappedFieldType)) {
        selections.push({
          kind: "Field",
          name: { kind: "Name", value: fieldName },
        });
      }

      // Max depth not reached, keep selecting object and it's scalar fields

      if (isObjectType(unwrappedFieldType) || isUnionType(unwrappedFieldType)) {
        const selectionSet = getSelectionSetFromOutputType(
          unwrappedFieldType,
          depth + 1
        );
        if (selectionSet)
          selections.push({
            kind: "Field",
            name: { kind: "Name", value: fieldName },
            selectionSet,
          });
      }
    });
  }

  return { kind: "SelectionSet", selections };
}

import { makeStyles } from "@material-ui/core";
import Editor, {
  BottomToolbar,
  BottomToolbarProps,
  CellPlugin,
  EditorProps,
} from "@react-page/editor";
import React, { createContext, memo, useContext, useMemo } from "react";

import {
  CloudinaryConfigContext,
  CloudinaryConfig,
} from "./plugins/cloudinaryImageUploadPlugin";
import { Plugins, addPaddingControlToPlugin } from "./plugins";

// When react page is used inside a dialog,
// the default zIndex (10) of the bottom drawer make it unable to display above the dialog.
// We need to use a context to inject customize the drawer zIndex when needed

export const ReactPageEditorConfigContext = createContext<{
  drawerZIndex?: number;
  colorPalette?: { title: string; colors: string[] }[];
}>({
  drawerZIndex: 1200,
});

const useToolbarStyles = makeStyles({
  drawer: {
    "&, & > *": {
      // Passed to MuiPaper
      // When zIndex is not customized, nothing will be set
      zIndex: ({ zIndex }: { zIndex?: number }) =>
        zIndex ? `${zIndex} !important` : undefined,
    },
    "& > div > div:first-child > div:first-child > div": {
      width: "100%",
    },
  },
});

const CustomToolbar = memo<BottomToolbarProps>(function CustomToolbar(props) {
  const { drawerZIndex } = useContext(ReactPageEditorConfigContext);
  const { drawer } = useToolbarStyles({ zIndex: drawerZIndex });
  return <BottomToolbar {...props} className={drawer} />;
});

export type ReactPageEditorProps = Omit<EditorProps, "cellPlugins"> & {
  drawerZIndex?: number;
  colorPalette?: { title: string; colors: string[] }[];
  plugins: Partial<Record<keyof typeof Plugins, boolean>>;
  customPlugins?: CellPlugin<any, any>[];
  cloudinaryConfig?: CloudinaryConfig;
};

export const ReactPageEditor = ({
  drawerZIndex,
  colorPalette,
  components,
  plugins,
  /**
   * 'demo' with 'docs_upload_example_us_preset' is the official testing upload bucket
   * @todo: use wemakeapp testing bucket
   */
  cloudinaryConfig = {
    cloudName: "demo",
    uploadPreset: "docs_upload_example_us_preset",
  },
  customPlugins = [],
  ...props
}: ReactPageEditorProps) => {
  const activePlugins = useMemo(() => {
    // Filtering the enabled plugins only

    const enabledPlugins = Object.entries(Plugins)
      .filter(([name]) => plugins[name as keyof typeof Plugins])
      .map(([, plugin]) => plugin);

    // Provide padding control to all plugins
    return [...enabledPlugins, ...customPlugins].map((plugin) =>
      addPaddingControlToPlugin(plugin)
    ) as CellPlugin[];
  }, [customPlugins, plugins]);

  return (
    <ReactPageEditorConfigContext.Provider
      value={{ drawerZIndex, colorPalette }}
    >
      <CloudinaryConfigContext.Provider value={cloudinaryConfig}>
        <Editor
          {...props}
          components={{
            BottomToolbar: CustomToolbar,
            ...components,
          }}
          cellPlugins={activePlugins}
        />
      </CloudinaryConfigContext.Provider>
    </ReactPageEditorConfigContext.Provider>
  );
};

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useMemo } from "react";

//
function omitDeep(obj: Record<string, any>, key: string): Record<string, any> {
  const keys = Object.keys(obj);
  const newObj = {} as Record<string, any>;
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null)
        newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr: any[], key: string): any[] {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation);
});

export function useApollo(url?: string | null) {
  const store = useMemo(
    () =>
      url
        ? new ApolloClient({
            ssrMode: false,
            cache: new InMemoryCache(),
            link: cleanTypenameLink.concat(new HttpLink({ uri: url })),
          })
        : null,
    [url]
  );
  return store;
}

/**
 * Ref: https://github.com/sindresorhus/array-move/blob/main/index.js
 *
 * Clones the given `array`, moves the item to a new position in the new array, and then returns the new array. The given `array` is not mutated.
 * @param array - The array with the item to move.
 * @param from - Index of item to move. If negative, it will begin that many elements from the end.
 * @param to - Index of where to move the item. If negative, it will begin that many elements from the end.
 * @returns A new array with the item moved to the new position.
 * @example
 * 	```
 *   	const input = ['a', 'b', 'c'];
 *   	const array1 = arrayMove(input, 1, 2);
 *   	console.log(array1);
 *   	//=> ['a', 'c', 'b']
 *   	const array2 = arrayMove(input, -1, 0);
 *   	console.log(array2);
 *   	//=> ['c', 'a', 'b']
 *   	const array3 = arrayMove(input, -2, -3);
 *   	console.log(array3);
 *   	//=> ['b', 'a', 'c']
 *   	```
 */
export function arrayMove<T>(
  array: ReadonlyArray<T>,
  from: number,
  to: number
): T[] {
  const newArray = [...array];
  const startIndex = from < 0 ? newArray.length + from : from;

  if (startIndex >= 0 && startIndex < newArray.length) {
    const endIndex = to < 0 ? newArray.length + to : to;

    const [item] = newArray.splice(from, 1);
    newArray.splice(endIndex, 0, item);
  }
  return newArray;
}

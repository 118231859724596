import { useProject } from "@/contexts";
import { Box, Button, Popover } from "@material-ui/core";
import clsx from "clsx";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useRouter } from "next/router";
import { FormEvent, ReactNode } from "react";
import { useFilterStyles } from "./filterStyles";

type FilterButtonProps = {
  name: string;
  valueStr: ReactNode;
  content: ReactNode;
  onApply: () => void;
  onClear: () => void;
};

export const FilterButton = ({
  name,
  valueStr,
  content,
  onApply,
  onClear,
}: FilterButtonProps) => {
  const { button, active, focus } = useFilterStyles();
  const { query } = useRouter();
  const { project } = useProject();
  const { headerAlias } = project ?? {};
  const alias = headerAlias?.[query.entity as string]?.[name] ?? name;
  const popupState = usePopupState({
    variant: "popover",
    popupId: `${name}Filter`,
  });

  const handleApply = (e: FormEvent) => {
    e.preventDefault();
    onApply();
    popupState.close();
  };

  const handleClear = () => {
    onClear();
    popupState.close();
  };

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        className={clsx(button, {
          [active]: !!valueStr,
          [focus]: popupState.isOpen,
        })}
        variant="outlined"
        disableRipple
        disableElevation
      >
        {alias + (valueStr ? `: ${valueStr}` : "")}
      </Button>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box component="form" onSubmit={handleApply} p={3}>
          <Box mb={2}>{content}</Box>

          <Box display="flex" justifyContent="space-between">
            <Button
              variant="text"
              type="submit"
              color="primary"
              disableElevation
            >
              Apply
            </Button>
            <Button variant="text" onClick={handleClear} disableElevation>
              Clear
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { FormFieldProps, Option, SelectInput } from "@/components/input";
import { useProject } from "@/contexts";
import { EntityType } from "@/helpers/graphqlDocgen";
import { useCmsQueryAndInput } from "@/hooks/cms";
import { getEntityDisplayString } from "@/helpers/getEntityDisplayString";

export const EntitySelectInput = ({
  label,
  name,
  entity,
  required,
  disabled,
  isMulti = false,
  isPrisma = false,
}: FormFieldProps & {
  entity: string;
  isMulti?: boolean;
  isPrisma?: boolean;
}) => {
  const { project } = useProject();

  const { query } = useCmsQueryAndInput({ entity, isMany: true });
  const { data, loading } = useQuery(query!);
  const queryDef = query?.definitions[0];
  if (queryDef?.kind !== "OperationDefinition") {
    console.error(JSON.stringify(query), " is not DefinitionNode");
    return null;
  }

  // Get the name of the query from the DocumentNode
  const queryName = queryDef.name?.value ?? `cmsGetMany${entity}`; // Fallback if anything gone wrong

  // The real data we are getting
  const entityList = data?.[queryName];
  if (loading) {
    return <Typography>Loading</Typography>;
  }
  if (!entityList || !Array.isArray(entityList)) {
    console.error("Data error: data is not array");
    return <Typography>Error</Typography>;
  }
  const options: Option[] = (entityList as EntityType[]).map((item) => ({
    value: item.id,
    label: getEntityDisplayString(project, item), // @todo get some meaningful text to show in select
  }));

  return (
    <SelectInput
      isPrisma={isPrisma}
      disabled={disabled}
      isMulti={isMulti}
      required={required}
      options={options}
      name={name}
      label={label}
    />
  );
};

import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { LoadingScreen } from "@/components/common/LoadingScreen";
import { getEmailLinkUrl } from "@/services/firebase/auth/getEmailLinkUrl";
import * as Sentry from "@sentry/nextjs";

import { logEvent, auth } from "@/services/firebase/firebase";
import { useRouter } from "next/router";
import { useNotificationContext } from "@/contexts/notification";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { mapUserData } from "./helpers/mapUserData";
import { removeUserCookie, setUserCookie } from "./helpers/userCookies";

import {
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signOut as firebaseSignOut,
  onIdTokenChanged as firebaseOnIdTokenChanged,
  User,
  signInWithEmailAndPassword,
} from "firebase/auth";

const UNAUTH_ROUTES = ["/", "/signIn", "/finishEmailLinkSignIn"];

type AuthContextType = {
  authInProgress: boolean;
  setAuthInProgress: (b: boolean) => void;
  user: (Partial<User> & Partial<ReturnType<typeof mapUserData>>) | null;
  onIdTokenChanged: boolean;
  signInWithGoogle: () => void;
  signInWithEmailLink: (email: string, onSuccess?: () => void) => void;
  validateEmailLinkApiKey: (prop: {
    email: string;
    url: string;
    onSuccess?: () => void;
    onFailure?: () => void;
  }) => void;
  signInWithEmailPassword: (
    email: string,
    password: string,
    remember: boolean
  ) => void;
  signOut: (slient?: boolean) => void;
};

const AuthContext = createContext<AuthContextType>({
  authInProgress: false,
  setAuthInProgress: () => {},
  user: null,
  onIdTokenChanged: false,
  signInWithGoogle: () => {},
  signInWithEmailLink: () => {},
  validateEmailLinkApiKey: () => {},
  signInWithEmailPassword: () => {},
  signOut: () => {},
});

// @todo separate different sign in provider into different hooks
// context contains only general user function
export const AuthProvider: FC = ({ children }) => {
  const { push, pathname, query } = useRouter();

  const { openNotification } = useNotificationContext();
  const { handleFirebaseAuthError } = useErrorHandling();
  const queryClient = useQueryClient();

  const [user, setUser] = useState<Partial<User> | null>(null);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [onIdTokenChanged, setOnIdTokenChanged] = useState(false);

  const [
    googleProvider,
    setGoogleProvider,
  ] = useState<GoogleAuthProvider | null>(null);

  useEffect(() => {
    if (!auth) return;
    if (!googleProvider) {
      const google = new GoogleAuthProvider();
      google.setCustomParameters({
        prompt: "select_account",
      });
      setGoogleProvider(google);
    }
  }, [googleProvider]);

  const signInWithGoogle = async () => {
    if (!googleProvider) return;
    setAuthInProgress(true);

    try {
      await signInWithPopup(auth, googleProvider);
      logEvent("login", { method: "google" });

      openNotification({
        message: "Logged in successfully",
        variant: "success",
      });
    } catch (error: any) {
      handleFirebaseAuthError(error, { login: "google" });
    } finally {
      setAuthInProgress(false);
    }
  };

  const signInWithEmailLink = async (email: string, onSuccess?: () => void) => {
    setAuthInProgress(true);

    sendSignInLinkToEmail(auth, email, {
      url: getEmailLinkUrl(window.location.hostname, email),
      handleCodeInApp: true,
    })
      .then(() => {
        logEvent("login", { method: "EmailLink" });

        openNotification({
          message: `Validation email has been sent to ${email}`,
          variant: "success",
        });

        onSuccess && onSuccess();
      })
      .catch((e) => {
        Sentry.captureException(e, {
          tags: { login: "emailLink" },
        });

        openNotification({
          message: "Email SignIn Error",
          variant: "danger",
        });
      })
      .finally(() => setAuthInProgress(false));
  };

  const validateEmailLinkApiKey: (prop: {
    email: string;
    url: string;
    onSuccess?: () => void;
    onFailure?: () => void;
  }) => void = async ({ email, url, onSuccess, onFailure }) => {
    // TODO: throw error if api key is invalid
    if (!isSignInWithEmailLink(auth, url)) {
      return;
    }

    await firebaseSignInWithEmailLink(auth, email, url)
      .then((res) => {
        const userData = mapUserData(res.user);
        setUserCookie(userData);

        openNotification({
          message: "Email Validated.",
          variant: "success",
        });

        onSuccess && onSuccess();
      })
      .catch((e) => {
        Sentry.captureException(e);

        openNotification({
          message: "Email Validation Error",
          variant: "danger",
        });

        onFailure && onFailure();
      })
      .finally(() => {
        setAuthInProgress(false);
      });
  };

  const signInWithEmailPassword = async (
    email: string,
    password: string,
    remember: boolean
  ) => {
    try {
      await setPersistence(
        auth,
        remember ? browserLocalPersistence : browserSessionPersistence
      );

      await signInWithEmailAndPassword(auth, email, password);

      openNotification({
        message: "Logged in successfully",
        variant: "success",
      });
    } catch (e: any) {
      // error codes: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_12
      const ignoreErrorCodes = [
        "auth/invalid-email",
        "auth/user-not-found",
        "auth/wrong-password",
      ];

      openNotification({
        message: "Email Password SignIn Error",
        variant: "danger",
      });

      if (!ignoreErrorCodes.includes(e.code)) {
        Sentry.withScope((scope) => {
          scope.setTag("action", "SIGN_IN_WITH_GOOGLE");
          scope.setTag("errorCode", e.code);
          Sentry.captureException(e);
        });
      }
    }
  };

  const signOut = async (slient?: boolean) => {
    if (authInProgress) return;
    setAuthInProgress(true);

    try {
      setOnIdTokenChanged(false);
      await firebaseSignOut(auth);

      if (!slient) {
        openNotification({
          message: "Logged out successfully",
          variant: "success",
        });
      }

      push("/signIn");
    } catch (error: any) {
      Sentry.captureException(error);
    } finally {
      setAuthInProgress(false);
    }
  };

  // Redirect user to sign in page if not sign-in,
  // and redirect user to previous/dashboard page if already signed in
  useEffect(() => {
    if (!user && onIdTokenChanged && !UNAUTH_ROUTES.includes(pathname)) {
      push({
        pathname: `/signIn`,
        query: pathname ? { pathname, ...query } : undefined,
      });
      return;
    }

    if (user && onIdTokenChanged && UNAUTH_ROUTES.includes(pathname)) {
      const { pathname: _pathname, ...redirectQuery } = query as Record<
        string,
        string
      >;
      const redirectPath = "/projects";

      push(
        _pathname ? { pathname: _pathname, query: redirectQuery } : redirectPath
      );
    }
  }, [user, push, pathname, query, onIdTokenChanged]);

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date

    firebaseOnIdTokenChanged(auth, (user) => {
      if (user) {
        const userData = mapUserData(user);
        setUserCookie(userData);
        setUser(userData);
        setOnIdTokenChanged(true);
      } else {
        onSignOut();
      }
    });
  }, []);

  function onSignOut() {
    removeUserCookie();
    setUser(null);
    setOnIdTokenChanged(true);
    queryClient.clear();
  }

  if (!onIdTokenChanged) {
    return <LoadingScreen text="Loading User" />;
  }

  return (
    <AuthContext.Provider
      value={{
        authInProgress,
        setAuthInProgress,
        user,
        signInWithGoogle,
        signInWithEmailLink,
        validateEmailLinkApiKey,
        signInWithEmailPassword,
        signOut,
        onIdTokenChanged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import { useRouter } from "next/router";
import { useCmsQueryAndInput } from "@/hooks/cms";

export const useEntityTableOrderBy = (
  entity: string
): Record<string, any> | undefined => {
  const router = useRouter();
  const { inputObjectMap } =
    useCmsQueryAndInput({ entity, isMany: true }) ?? {};
  if (!inputObjectMap || !inputObjectMap?.orderBy) return;

  /* get default orderBy */
  const isCreatedAtSortable = "createdAt" in inputObjectMap.orderBy;
  const defaultOrderBy = isCreatedAtSortable ? [{ createdAt: "desc" }] : [];

  /* get order by field from url */
  const queryObj = router.query as Record<string, string>;
  const orderByArr = Object.entries(queryObj).filter(([name]) =>
    name.includes("orderBy")
  );

  if (orderByArr.length === 0) return defaultOrderBy;

  const normalizedOrderByArr = orderByArr.reduce((arr, [name, value]) => {
    const orderByKey = name.replace(/orderBy/, "");
    if (orderByKey in inputObjectMap.orderBy)
      return [...arr, { [orderByKey]: value }];
    return arr;
  }, [] as Record<string, string>[]);

  if (normalizedOrderByArr.some((item) => "createdAt" in item))
    return normalizedOrderByArr;

  return [...normalizedOrderByArr, ...defaultOrderBy];
};

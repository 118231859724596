import { ORDER_BY_IDENTIFIER } from "@/components/tailwind/filter/types";
import { useQueryFilter } from "@/components/tailwind/filter/useQueryFilter";
import { useProject } from "@/contexts";
import { SortOrder } from "@/features/dearshare/generated/generated-hooks";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { Button } from "@material-ui/core";
import { useRouter } from "next/router";
import "react-datepicker/dist/react-datepicker.css";
import { ObjectParam } from "use-query-params";

export const SortQueryField = ({
  name,
  orderByFields,
}: {
  name: string;
  orderByFields: Record<string, any>;
}) => {
  const { project } = useProject();
  const { query } = useRouter();
  const { headerAlias } = project ?? {};

  const {
    query: { queryValue, setQueryValue },
  } = useQueryFilter({
    name: ORDER_BY_IDENTIFIER,
    queryParam: ObjectParam,
  });

  const alias = headerAlias?.[query.entity as string]?.[name] ?? name;
  if (!(name in orderByFields))
    return <span style={{ textTransform: "capitalize" }}>{alias}</span>;

  /**
   * Loops through the filter state in the order of null -> true -> false -> null
   */
  const loopState = () => {
    if (queryValue && queryValue?.field === name) {
      if (queryValue?.order === SortOrder.Desc) {
        setQueryValue({
          field: name,
          order: SortOrder.Asc,
        });
        return;
      }
      if (queryValue?.order === SortOrder.Asc) {
        setQueryValue(undefined);
        return;
      }
    }

    setQueryValue({
      field: name,
      order: SortOrder.Desc,
    });
  };

  const icon =
    queryValue?.field === name
      ? queryValue?.order === SortOrder.Desc
        ? ArrowDownIcon
        : queryValue?.order === SortOrder.Asc && ArrowUpIcon
      : null;

  return (
    <Button
      style={{ marginLeft: -8, textTransform: "none" }}
      endIcon={icon}
      onClick={loopState}
      variant="text"
      disableRipple
      disableElevation
    >
      {alias}
    </Button>
  );
};

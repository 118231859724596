/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  CmsArticleID: any;
  CmsBookingID: any;
  CmsFacebookReviewID: any;
  CmsNavItemID: any;
  CmsShopID: any;
  CmsTreatmentID: any;
  /** React Page Content */
  ReactPageContent: any;
};



export type AddArticleInput = {
  title: Scalars['String'];
  slug: Scalars['String'];
};

export enum ArticleType {
  Post = 'POST',
  Page = 'PAGE',
  Treatment = 'TREATMENT',
  Sitepage = 'SITEPAGE'
}

export type Article = {
  __typename?: 'Article';
  id: Scalars['ID'];
  /** Description (meta) */
  abstract?: Maybe<Scalars['String']>;
  banner?: Maybe<ImageUpload>;
  dateString?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  displayId?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleType>;
  content?: Maybe<Scalars['ReactPageContent']>;
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  treatment?: Maybe<Treatment>;
};

export enum ArticleStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Deleted = 'DELETED'
}


export type CmsUpdateArticleInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
  /** Display ID */
  displayId?: Maybe<Scalars['String']>;
  /** Author */
  author?: Maybe<Scalars['String']>;
  /** Banner */
  banner?: Maybe<ImageUploadInput>;
  /** Article Type */
  type?: Maybe<ArticleType>;
  /** Treatment */
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  /** Description (meta) */
  abstract?: Maybe<Scalars['String']>;
  /** Content */
  content?: Maybe<Scalars['ReactPageContent']>;
};

export type CmsCreateArticleInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
  /** Display ID */
  displayId?: Maybe<Scalars['String']>;
  /** Author */
  author?: Maybe<Scalars['String']>;
  /** Banner */
  banner?: Maybe<ImageUploadInput>;
  /** Article Type */
  type?: Maybe<ArticleType>;
  /** Treatment */
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  /** Description (meta) */
  abstract?: Maybe<Scalars['String']>;
  /** Content */
  content?: Maybe<Scalars['ReactPageContent']>;
};

export type CmsArticleWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsArticleWhereManyInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
};

export type CmsArticle = {
  __typename?: 'CmsArticle';
  id: Scalars['ID'];
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
  /** Display ID */
  displayId?: Maybe<Scalars['String']>;
  /** Author */
  author?: Maybe<Scalars['String']>;
  /** Banner */
  banner?: Maybe<ImageUpload>;
  /** Article Type */
  type?: Maybe<ArticleType>;
  /** Treatment */
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  /** Treatment */
  treatment?: Maybe<CmsTreatment>;
  /** Description (meta) */
  abstract?: Maybe<Scalars['String']>;
  /** Content */
  content?: Maybe<Scalars['ReactPageContent']>;
  /** Created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CmsUpdateBookingInput = {
  /** Customer Name */
  customerName?: Maybe<Scalars['String']>;
  /** Customer Phone */
  customerPhone?: Maybe<Scalars['String']>;
  /** Shop (Not yet able to read from treatment available stores) */
  shopId?: Maybe<Scalars['CmsShopID']>;
  /** Treatment */
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  /** Starting Hour */
  startHour?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
};

export type CmsCreateBookingInput = {
  /** Customer Name */
  customerName: Scalars['String'];
  /** Customer Phone */
  customerPhone: Scalars['String'];
  /** Shop (Not yet able to read from treatment available stores) */
  shopId?: Maybe<Scalars['CmsShopID']>;
  /** Treatment */
  treatmentId: Scalars['CmsTreatmentID'];
  /** Starting Hour */
  startHour?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['Date'];
};

export type CmsBookingWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsBookingWhereManyInput = {
  /** Receipt ID */
  receiptId?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Price */
  price?: Maybe<Scalars['Int']>;
};

export type CmsBooking = {
  __typename?: 'CmsBooking';
  id: Scalars['ID'];
  receiptId: Scalars['String'];
  /** Customer Name */
  customerName: Scalars['String'];
  /** Customer Phone */
  customerPhone: Scalars['String'];
  /** Starting Hour */
  startHour?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['Date'];
  /** Shop */
  shopId?: Maybe<Scalars['CmsShopID']>;
  /** Shop */
  shop?: Maybe<CmsShop>;
  /** Treatment */
  treatmentId?: Maybe<Scalars['CmsTreatmentID']>;
  /** Treatment */
  treatment?: Maybe<CmsTreatment>;
  /** Payment Intent ID */
  paymentIntentId?: Maybe<Scalars['String']>;
  /** Payment Status */
  paymentIntentStatus?: Maybe<PaymentIntentStatusEnum>;
  /** Amount */
  amount: Scalars['Float'];
  /** Created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CmsUpdateFacebookReviewInput = {
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
  /** Avatar Url */
  avatarSrc?: Maybe<Scalars['String']>;
  avatar?: Maybe<ImageUploadInput>;
};

export type CmsCreateFacebookReviewInput = {
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
  avatar?: Maybe<ImageUploadInput>;
};

export type CmsFacebookReviewWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsFacebookReviewWhereManyInput = {
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
};

export type CmsFacebookReview = {
  __typename?: 'CmsFacebookReview';
  id: Scalars['ID'];
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
  avatar?: Maybe<ImageUpload>;
  /** Created */
  createdAt?: Maybe<Scalars['Date']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LinkContent = {
  __typename?: 'LinkContent';
  url?: Maybe<Scalars['String']>;
};

export type NavContent = LinkContent | CmsArticle;


export type CmsUpdateNavItemInput = {
  /** Children items */
  itemIds?: Maybe<Array<Scalars['CmsNavItemID']>>;
  /** Parent item */
  parentId?: Maybe<Scalars['CmsNavItemID']>;
  /** Item Name */
  title?: Maybe<Scalars['String']>;
  /** Article */
  articleId?: Maybe<Scalars['CmsArticleID']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
};

export type CmsCreateNavItemInput = {
  /** Children items */
  itemIds?: Maybe<Array<Scalars['CmsNavItemID']>>;
  /** Parent item */
  parentId?: Maybe<Scalars['CmsNavItemID']>;
  /** Item Name */
  title?: Maybe<Scalars['String']>;
  /** Article */
  articleId?: Maybe<Scalars['CmsArticleID']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
};

export type CmsNavItemWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsNavItemWhereManyInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsNavItem = {
  __typename?: 'CmsNavItem';
  id: Scalars['ID'];
  /** Menu Order */
  index?: Maybe<Scalars['Int']>;
  /** Item Name */
  title: Scalars['String'];
  /** Children items */
  itemIds?: Maybe<Array<Scalars['CmsNavItemID']>>;
  /** Children items */
  items?: Maybe<Array<CmsNavItem>>;
  /** Parent item */
  parentId?: Maybe<Scalars['CmsNavItemID']>;
  articleId?: Maybe<Scalars['CmsArticleID']>;
  url?: Maybe<Scalars['String']>;
  /** Nav Content */
  content?: Maybe<NavContent>;
  /** Created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CmsUpdateShopInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Address */
  address?: Maybe<Scalars['String']>;
  /** Phone */
  phone?: Maybe<Scalars['String']>;
  /** Mobile */
  mobile?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Display order */
  order?: Maybe<Scalars['Int']>;
};

export type CmsCreateShopInput = {
  /** Title */
  title: Scalars['String'];
  /** Address */
  address: Scalars['String'];
  /** Phone */
  phone?: Maybe<Scalars['String']>;
  /** Mobile */
  mobile?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Display order */
  order?: Maybe<Scalars['Int']>;
};

export type CmsShopWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsShopWhereManyInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Address */
  address?: Maybe<Scalars['String']>;
  /** Phone */
  phone?: Maybe<Scalars['String']>;
  /** Mobile */
  mobile?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Display order */
  order?: Maybe<Scalars['Int']>;
};

export type CmsShop = {
  __typename?: 'CmsShop';
  id: Scalars['ID'];
  /** Title */
  title: Scalars['String'];
  /** Address */
  address: Scalars['String'];
  /** Phone */
  phone?: Maybe<Scalars['String']>;
  /** Mobile */
  mobile?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Display order */
  order?: Maybe<Scalars['Int']>;
};


export type CmsUpdateTreatmentInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Image */
  image?: Maybe<ImageUploadInput>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Available Shops IDs */
  shopIds?: Maybe<Array<Scalars['CmsShopID']>>;
  /** Original Price */
  discountPrice?: Maybe<Scalars['Float']>;
  /** Trial Price (Before Disount) */
  price?: Maybe<Scalars['Float']>;
  /** Deposit */
  deposit?: Maybe<Scalars['Float']>;
  /** Have deposit discount */
  collectDepositFirst?: Maybe<Scalars['Boolean']>;
  /** Non busy hour only */
  nonBusyHourOnly?: Maybe<Scalars['Boolean']>;
};

export type CmsCreateTreatmentInput = {
  /** Title */
  title: Scalars['String'];
  /** Image */
  image?: Maybe<ImageUploadInput>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Available Shops IDs */
  shopIds?: Maybe<Array<Scalars['CmsShopID']>>;
  /** Original Price */
  discountPrice?: Maybe<Scalars['Float']>;
  /** Trial Price (Before Disount) */
  price?: Maybe<Scalars['Float']>;
  /** Deposit */
  deposit?: Maybe<Scalars['Float']>;
  /** Have deposit discount */
  collectDepositFirst?: Maybe<Scalars['Boolean']>;
  /** Non busy hour only */
  nonBusyHourOnly?: Maybe<Scalars['Boolean']>;
};

export type CmsTreatmentWhereOneInput = {
  id: Scalars['ID'];
};

export type CmsTreatmentWhereManyInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Original Price */
  discountPrice?: Maybe<Scalars['Float']>;
  /** Trial Price (Before Disount) */
  price?: Maybe<Scalars['Float']>;
};

export type CmsTreatment = {
  __typename?: 'CmsTreatment';
  id: Scalars['ID'];
  /** Title */
  title: Scalars['String'];
  /** Image */
  image?: Maybe<ImageUpload>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Original Price */
  discountPrice?: Maybe<Scalars['Float']>;
  /** Trial Price (Before Disount) */
  price?: Maybe<Scalars['Float']>;
  /** Deposit */
  deposit?: Maybe<Scalars['Float']>;
  /** Have deposit discount */
  collectDepositFirst?: Maybe<Scalars['Boolean']>;
  /** Non busy hour only */
  nonBusyHourOnly?: Maybe<Scalars['Boolean']>;
  /** Available Shops */
  shopIds?: Maybe<Array<Scalars['CmsShopID']>>;
  /** Available Shops */
  shops?: Maybe<Array<CmsShop>>;
  /** Created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacebookReview = {
  __typename?: 'FacebookReview';
  id?: Maybe<Scalars['ID']>;
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['Date']>;
  /** Comment */
  comment?: Maybe<Scalars['String']>;
  avatar?: Maybe<ImageUpload>;
};

export enum ImageUploadType {
  Cloudinary = 'CLOUDINARY',
  Url = 'URL'
}

export type ImageUpload = {
  __typename?: 'ImageUpload';
  /** Upload Type */
  uploadType?: Maybe<ImageUploadType>;
  url?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  cloudName?: Maybe<Scalars['String']>;
};

export type ImageUploadInput = {
  url?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
  uploadType?: Maybe<ImageUploadType>;
  publicId?: Maybe<Scalars['String']>;
  cloudName?: Maybe<Scalars['String']>;
};

export type CreateNavItemArgs = {
  /** Children items */
  itemIds?: Maybe<Array<Scalars['CmsNavItemID']>>;
  /** Parent items */
  parentId?: Maybe<Scalars['CmsNavItemID']>;
  /** Item Name */
  title?: Maybe<Scalars['String']>;
  /** Article */
  articleId?: Maybe<Scalars['CmsArticleID']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
};

export type NavItemsInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  /** Article */
  articleId?: Maybe<Scalars['String']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
  /** Menu Order */
  index?: Maybe<Scalars['Int']>;
  itemIds?: Maybe<Array<Scalars['String']>>;
};

export type NavItem = {
  __typename?: 'NavItem';
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Scalars['String']>>;
  articleId?: Maybe<Scalars['ID']>;
  article?: Maybe<Article>;
  url?: Maybe<Scalars['String']>;
};


export type Treatment = {
  __typename?: 'Treatment';
  id: Scalars['ID'];
  /** Title */
  title: Scalars['String'];
  /** Image */
  image?: Maybe<ImageUpload>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Price */
  price: Scalars['Float'];
  /** Discount Price */
  discountPrice?: Maybe<Scalars['Float']>;
  /** Deposit */
  deposit?: Maybe<Scalars['Float']>;
  /** Have deposit discount */
  collectDepositFirst?: Maybe<Scalars['Boolean']>;
  /** Non busy hour only */
  nonBusyHourOnly?: Maybe<Scalars['Boolean']>;
  /** Created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Available Shops IDs */
  shopIds?: Maybe<Array<Scalars['CmsShopID']>>;
  /** Available Shops */
  shops?: Maybe<Array<Shop>>;
};

export type PaymentIntentResponse = {
  __typename?: 'PaymentIntentResponse';
  clientSecret: Scalars['String'];
  receiptId: Scalars['String'];
};

export enum PaymentIntentStatusEnum {
  Created = 'CREATED',
  Succeeded = 'SUCCEEDED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED'
}

export type CreateBookingPaymentIntentInput = {
  treatmentId: Scalars['ID'];
  customerName: Scalars['String'];
  customerPhone: Scalars['String'];
  date: Scalars['Date'];
  startHour?: Maybe<Scalars['Int']>;
  shopId: Scalars['String'];
};

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['ID'];
  /** Title */
  title: Scalars['String'];
  /** Address */
  address: Scalars['String'];
  /** Phone */
  phone?: Maybe<Scalars['String']>;
  /** Mobile */
  mobile?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Display order */
  order: Scalars['Int'];
};

export type AddUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  articles?: Maybe<Array<Article>>;
  cmsGetOneArticle?: Maybe<CmsArticle>;
  cmsGetManyArticle?: Maybe<Array<Maybe<CmsArticle>>>;
  cmsGetOneBooking?: Maybe<CmsBooking>;
  cmsGetManyBooking?: Maybe<Array<Maybe<CmsBooking>>>;
  cmsGetOneFacebookReview?: Maybe<CmsFacebookReview>;
  cmsGetManyFacebookReview?: Maybe<Array<Maybe<CmsFacebookReview>>>;
  cmsGetOneNavItem?: Maybe<CmsNavItem>;
  cmsGetManyNavItem?: Maybe<Array<Maybe<CmsNavItem>>>;
  cmsGetOneShop?: Maybe<CmsShop>;
  cmsGetManyShop?: Maybe<Array<Maybe<CmsShop>>>;
  cmsGetOneTreatment?: Maybe<CmsTreatment>;
  cmsGetManyTreatment?: Maybe<Array<Maybe<CmsTreatment>>>;
  facebookReviews?: Maybe<Array<FacebookReview>>;
  navItems?: Maybe<Array<NavItem>>;
  treatments?: Maybe<Array<Treatment>>;
  shops?: Maybe<Array<Shop>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryArticleArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  displayId?: Maybe<Scalars['String']>;
};


export type QueryArticlesArgs = {
  type?: Maybe<ArticleType>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCmsGetOneArticleArgs = {
  where?: Maybe<CmsArticleWhereOneInput>;
};


export type QueryCmsGetManyArticleArgs = {
  where?: Maybe<CmsArticleWhereManyInput>;
};


export type QueryCmsGetOneBookingArgs = {
  where?: Maybe<CmsBookingWhereOneInput>;
};


export type QueryCmsGetManyBookingArgs = {
  where?: Maybe<CmsBookingWhereManyInput>;
};


export type QueryCmsGetOneFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereOneInput>;
};


export type QueryCmsGetManyFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereManyInput>;
};


export type QueryCmsGetOneNavItemArgs = {
  where?: Maybe<CmsNavItemWhereOneInput>;
};


export type QueryCmsGetManyNavItemArgs = {
  where?: Maybe<CmsNavItemWhereManyInput>;
};


export type QueryCmsGetOneShopArgs = {
  where?: Maybe<CmsShopWhereOneInput>;
};


export type QueryCmsGetManyShopArgs = {
  where?: Maybe<CmsShopWhereManyInput>;
};


export type QueryCmsGetOneTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereOneInput>;
};


export type QueryCmsGetManyTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereManyInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addArticle?: Maybe<Article>;
  cmsCreateOneArticle?: Maybe<CmsArticle>;
  cmsUpdateOneArticle?: Maybe<CmsArticle>;
  cmsUpdateManyArticle?: Maybe<Array<Maybe<CmsArticle>>>;
  cmsDeleteOneArticle?: Maybe<CmsArticle>;
  cmsDeleteManyArticle?: Maybe<Array<Maybe<CmsArticle>>>;
  cmsCreateOneBooking?: Maybe<CmsBooking>;
  cmsUpdateOneBooking?: Maybe<CmsBooking>;
  cmsUpdateManyBooking?: Maybe<Array<Maybe<CmsBooking>>>;
  cmsDeleteOneBooking?: Maybe<CmsBooking>;
  cmsDeleteManyBooking?: Maybe<Array<Maybe<CmsBooking>>>;
  cmsCreateOneFacebookReview?: Maybe<CmsFacebookReview>;
  cmsUpdateOneFacebookReview?: Maybe<CmsFacebookReview>;
  cmsUpdateManyFacebookReview?: Maybe<Array<Maybe<CmsFacebookReview>>>;
  cmsDeleteOneFacebookReview?: Maybe<CmsFacebookReview>;
  cmsDeleteManyFacebookReview?: Maybe<Array<Maybe<CmsFacebookReview>>>;
  cmsCreateOneNavItem?: Maybe<CmsNavItem>;
  cmsUpdateOneNavItem?: Maybe<CmsNavItem>;
  cmsUpdateManyNavItem?: Maybe<Array<Maybe<CmsNavItem>>>;
  cmsDeleteOneNavItem?: Maybe<CmsNavItem>;
  cmsDeleteManyNavItem?: Maybe<Array<Maybe<CmsNavItem>>>;
  cmsCreateOneShop?: Maybe<CmsShop>;
  cmsUpdateOneShop?: Maybe<CmsShop>;
  cmsUpdateManyShop?: Maybe<Array<Maybe<CmsShop>>>;
  cmsDeleteOneShop?: Maybe<CmsShop>;
  cmsDeleteManyShop?: Maybe<Array<Maybe<CmsShop>>>;
  cmsCreateOneTreatment?: Maybe<CmsTreatment>;
  cmsUpdateOneTreatment?: Maybe<CmsTreatment>;
  cmsUpdateManyTreatment?: Maybe<Array<Maybe<CmsTreatment>>>;
  cmsDeleteOneTreatment?: Maybe<CmsTreatment>;
  cmsDeleteManyTreatment?: Maybe<Array<Maybe<CmsTreatment>>>;
  createNavItem?: Maybe<CmsNavItem>;
  updateNavTree?: Maybe<Scalars['Boolean']>;
  CreateBookingPaymentIntent?: Maybe<PaymentIntentResponse>;
  addUser?: Maybe<User>;
};


export type MutationAddArticleArgs = {
  input?: Maybe<AddArticleInput>;
};


export type MutationCmsCreateOneArticleArgs = {
  data: CmsCreateArticleInput;
};


export type MutationCmsUpdateOneArticleArgs = {
  where?: Maybe<CmsArticleWhereOneInput>;
  data: CmsUpdateArticleInput;
};


export type MutationCmsUpdateManyArticleArgs = {
  where?: Maybe<CmsArticleWhereManyInput>;
  data: CmsUpdateArticleInput;
};


export type MutationCmsDeleteOneArticleArgs = {
  where?: Maybe<CmsArticleWhereOneInput>;
};


export type MutationCmsDeleteManyArticleArgs = {
  where?: Maybe<CmsArticleWhereManyInput>;
};


export type MutationCmsCreateOneBookingArgs = {
  data: CmsCreateBookingInput;
};


export type MutationCmsUpdateOneBookingArgs = {
  where?: Maybe<CmsBookingWhereOneInput>;
  data: CmsUpdateBookingInput;
};


export type MutationCmsUpdateManyBookingArgs = {
  where?: Maybe<CmsBookingWhereManyInput>;
  data: CmsUpdateBookingInput;
};


export type MutationCmsDeleteOneBookingArgs = {
  where?: Maybe<CmsBookingWhereOneInput>;
};


export type MutationCmsDeleteManyBookingArgs = {
  where?: Maybe<CmsBookingWhereManyInput>;
};


export type MutationCmsCreateOneFacebookReviewArgs = {
  data: CmsCreateFacebookReviewInput;
};


export type MutationCmsUpdateOneFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereOneInput>;
  data: CmsUpdateFacebookReviewInput;
};


export type MutationCmsUpdateManyFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereManyInput>;
  data: CmsUpdateFacebookReviewInput;
};


export type MutationCmsDeleteOneFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereOneInput>;
};


export type MutationCmsDeleteManyFacebookReviewArgs = {
  where?: Maybe<CmsFacebookReviewWhereManyInput>;
};


export type MutationCmsCreateOneNavItemArgs = {
  data: CmsCreateNavItemInput;
};


export type MutationCmsUpdateOneNavItemArgs = {
  where?: Maybe<CmsNavItemWhereOneInput>;
  data: CmsUpdateNavItemInput;
};


export type MutationCmsUpdateManyNavItemArgs = {
  where?: Maybe<CmsNavItemWhereManyInput>;
  data: CmsUpdateNavItemInput;
};


export type MutationCmsDeleteOneNavItemArgs = {
  where?: Maybe<CmsNavItemWhereOneInput>;
};


export type MutationCmsDeleteManyNavItemArgs = {
  where?: Maybe<CmsNavItemWhereManyInput>;
};


export type MutationCmsCreateOneShopArgs = {
  data: CmsCreateShopInput;
};


export type MutationCmsUpdateOneShopArgs = {
  where?: Maybe<CmsShopWhereOneInput>;
  data: CmsUpdateShopInput;
};


export type MutationCmsUpdateManyShopArgs = {
  where?: Maybe<CmsShopWhereManyInput>;
  data: CmsUpdateShopInput;
};


export type MutationCmsDeleteOneShopArgs = {
  where?: Maybe<CmsShopWhereOneInput>;
};


export type MutationCmsDeleteManyShopArgs = {
  where?: Maybe<CmsShopWhereManyInput>;
};


export type MutationCmsCreateOneTreatmentArgs = {
  data: CmsCreateTreatmentInput;
};


export type MutationCmsUpdateOneTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereOneInput>;
  data: CmsUpdateTreatmentInput;
};


export type MutationCmsUpdateManyTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereManyInput>;
  data: CmsUpdateTreatmentInput;
};


export type MutationCmsDeleteOneTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereOneInput>;
};


export type MutationCmsDeleteManyTreatmentArgs = {
  where?: Maybe<CmsTreatmentWhereManyInput>;
};


export type MutationCreateNavItemArgs = {
  data: CreateNavItemArgs;
};


export type MutationUpdateNavTreeArgs = {
  navItems?: Maybe<Array<NavItemsInput>>;
};


export type MutationCreateBookingPaymentIntentArgs = {
  input: CreateBookingPaymentIntentInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};

export type CmsArticleFragment = (
  { __typename?: 'CmsArticle' }
  & Pick<CmsArticle, 'id' | 'content' | 'title' | 'abstract' | 'slug' | 'displayId' | 'author' | 'type' | 'treatmentId' | 'createdAt' | 'updatedAt'>
  & { banner?: Maybe<(
    { __typename?: 'ImageUpload' }
    & Pick<ImageUpload, 'uploadType' | 'url' | 'alt' | 'publicId' | 'cloudName'>
  )>, treatment?: Maybe<(
    { __typename?: 'CmsTreatment' }
    & Pick<CmsTreatment, 'id' | 'title'>
  )> }
);

export type ImageUploadFragment = (
  { __typename?: 'ImageUpload' }
  & Pick<ImageUpload, 'uploadType' | 'url' | 'alt' | 'publicId'>
);

export type CmsTreatmentFragment = (
  { __typename?: 'CmsTreatment' }
  & Pick<CmsTreatment, 'id' | 'title' | 'description' | 'price' | 'discountPrice' | 'deposit' | 'collectDepositFirst' | 'shopIds'>
);

export type CmsCreateOneArticleMutationVariables = Exact<{
  data: CmsCreateArticleInput;
}>;


export type CmsCreateOneArticleMutation = (
  { __typename?: 'Mutation' }
  & { cmsCreateOneArticle?: Maybe<(
    { __typename?: 'CmsArticle' }
    & CmsArticleFragment
  )> }
);

export type CmsUpdateOneArticleMutationVariables = Exact<{
  data: CmsUpdateArticleInput;
  where: CmsArticleWhereOneInput;
}>;


export type CmsUpdateOneArticleMutation = (
  { __typename?: 'Mutation' }
  & { cmsUpdateOneArticle?: Maybe<(
    { __typename?: 'CmsArticle' }
    & CmsArticleFragment
  )> }
);

export type CmsGetManyArticleQueryVariables = Exact<{
  where?: Maybe<CmsArticleWhereManyInput>;
}>;


export type CmsGetManyArticleQuery = (
  { __typename?: 'Query' }
  & { cmsGetManyArticle?: Maybe<Array<Maybe<(
    { __typename?: 'CmsArticle' }
    & CmsArticleFragment
  )>>> }
);

export type CmsGetManyTreatmentQueryVariables = Exact<{
  where: CmsTreatmentWhereManyInput;
}>;


export type CmsGetManyTreatmentQuery = (
  { __typename?: 'Query' }
  & { cmsGetManyTreatment?: Maybe<Array<Maybe<(
    { __typename?: 'CmsTreatment' }
    & CmsTreatmentFragment
  )>>> }
);

export type CmsGetOneArticleQueryVariables = Exact<{
  where: CmsArticleWhereOneInput;
}>;


export type CmsGetOneArticleQuery = (
  { __typename?: 'Query' }
  & { cmsGetOneArticle?: Maybe<(
    { __typename?: 'CmsArticle' }
    & CmsArticleFragment
  )> }
);

export const CmsArticleFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CmsArticle"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CmsArticle"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"abstract"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"displayId"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"banner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadType"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"alt"}},{"kind":"Field","name":{"kind":"Name","value":"publicId"}},{"kind":"Field","name":{"kind":"Name","value":"cloudName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"treatmentId"}},{"kind":"Field","name":{"kind":"Name","value":"treatment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode<CmsArticleFragment, unknown>;
export const ImageUploadFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ImageUpload"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ImageUpload"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadType"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"alt"}},{"kind":"Field","name":{"kind":"Name","value":"publicId"}}]}}]} as unknown as DocumentNode<ImageUploadFragment, unknown>;
export const CmsTreatmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CmsTreatment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CmsTreatment"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"discountPrice"}},{"kind":"Field","name":{"kind":"Name","value":"deposit"}},{"kind":"Field","name":{"kind":"Name","value":"collectDepositFirst"}},{"kind":"Field","name":{"kind":"Name","value":"shopIds"}}]}}]} as unknown as DocumentNode<CmsTreatmentFragment, unknown>;
export const CmsCreateOneArticleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"cmsCreateOneArticle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsCreateArticleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cmsCreateOneArticle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CmsArticle"}}]}}]}},...CmsArticleFragmentDoc.definitions]} as unknown as DocumentNode<CmsCreateOneArticleMutation, CmsCreateOneArticleMutationVariables>;
export const CmsUpdateOneArticleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"cmsUpdateOneArticle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsUpdateArticleInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsArticleWhereOneInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cmsUpdateOneArticle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CmsArticle"}}]}}]}},...CmsArticleFragmentDoc.definitions]} as unknown as DocumentNode<CmsUpdateOneArticleMutation, CmsUpdateOneArticleMutationVariables>;
export const CmsGetManyArticleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cmsGetManyArticle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsArticleWhereManyInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cmsGetManyArticle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CmsArticle"}}]}}]}},...CmsArticleFragmentDoc.definitions]} as unknown as DocumentNode<CmsGetManyArticleQuery, CmsGetManyArticleQueryVariables>;
export const CmsGetManyTreatmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cmsGetManyTreatment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsTreatmentWhereManyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cmsGetManyTreatment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CmsTreatment"}}]}}]}},...CmsTreatmentFragmentDoc.definitions]} as unknown as DocumentNode<CmsGetManyTreatmentQuery, CmsGetManyTreatmentQueryVariables>;
export const CmsGetOneArticleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cmsGetOneArticle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsArticleWhereOneInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cmsGetOneArticle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CmsArticle"}}]}}]}},...CmsArticleFragmentDoc.definitions]} as unknown as DocumentNode<CmsGetOneArticleQuery, CmsGetOneArticleQueryVariables>;
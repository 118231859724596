import { Box, FormLabel, Slider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connectField } from "uniforms";

export const SliderInput = connectField(
  ({ onChange, value, label, min, max, step }: any) => {
    const [localValue, setLocalValue] = useState<number | string>(
      (value ?? 0) as number
    );
    const parsedValue =
      typeof localValue === "number" ? localValue : parseInt(localValue, 10);

    useEffect(() => {
      if (!Number.isNaN(parsedValue)) {
        onChange?.(parsedValue);
      }
    }, [parsedValue, onChange]);
    return (
      <Box my={2}>
        <FormLabel component="label">{label}</FormLabel>
        <Box display="flex" alignItems="center">
          <Box mr={1} width={40}>
            <TextField
              fullWidth
              value={localValue ?? ""}
              onChange={(e) => {
                setLocalValue(e.target.value);
              }}
            />
          </Box>
          <Slider
            // onChange={(_, newValue: number) => setLocalValue?.(newValue)}
            //@ts-ignore
            onChange={(_, newValue: number) => setLocalValue(newValue)}
            step={step ?? 1}
            value={parsedValue}
            min={min ?? 0}
            max={max ?? 100}
            valueLabelDisplay="off"
          />
        </Box>
      </Box>
    );
  }
);

import { cn } from "@/helpers/cn";

export type BadgeProps = {
  className?: string;
  name: string;
  size?: "small" | "normal" | "large";
  variant?:
    | "primary"
    | "secondary"
    | "active"
    | "inactive"
    | "warning"
    | "danger";
};

const classes = {
  base: "whitespace-nowrap rounded-full font-medium",
  size: {
    small: "px-3 py-0.5 text-xs",
    normal: "px-4 py-1 text-sm",
    large: "px-5 py-2 text-base",
  },
  variant: {
    primary: "bg-blue-50 text-primary",
    secondary: "bg-primary text-white",
    active: "bg-green-100 text-green-800",
    inactive: "bg-gray-100 text-gray-800",
    warning: "bg-yellow-100 text-yellow-800",
    danger: "bg-red-100 text-red-800",
  },
};

export const Badge = ({
  className,
  name,
  size = "small",
  variant = "primary",
}: BadgeProps) => {
  return (
    <span
      className={cn(
        classes.base,
        classes.size[size],
        classes.variant[variant],
        className,
      )}
    >
      {name}
    </span>
  );
};

import {
  GraphQLEnumType,
  GraphQLInputFieldMap,
  GraphQLInputObjectType,
  GraphQLInputType,
} from "graphql/type";
import { InputObjectFieldTypedefMap } from "./graphqlDocgen/types";
import { Option } from "@/components/input";

export const getEnumValuesFromInputObject = (
  rootObject: (InputObjectFieldTypedefMap | GraphQLInputFieldMap) & {
    name?: string;
  },
  path: string
): Option[] => {
  const keys = path.split(".");
  const [root, nextKey] = keys;
  const argTypeName =
    (rootObject[root]?.type as GraphQLInputType & { name?: string })?.name ??
    "";

  const isEnum = argTypeName.includes("Enum");
  if (isEnum) {
    const values = ((rootObject[root]
      ?.type as GraphQLInputObjectType).getFields().equals
      .type as GraphQLEnumType).getValues();
    return normalizeEnums(values);
  }

  const isObject = argTypeName.includes("WhereInput");
  if (!isObject || !nextKey) return [];

  const nextRootObject = (rootObject[root]
    .type as GraphQLInputObjectType).getFields();
  return getEnumValuesFromInputObject(nextRootObject, keys.slice(1).join("."));
};

const normalizeEnums = (enumValues: { value: string }[]) => {
  return enumValues.map((val) => ({ label: val.value, value: val.value }));
};

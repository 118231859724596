import { Box, TextField } from "@material-ui/core";
import {
  FilterButton,
  useFilterStyles,
} from "@/components/table/UrlQueryField";
import { useQueryState } from "next-usequerystate";
import { useState } from "react";
import * as serializers from "@/components/table/UrlQueryField/serializers";

export const NumberQueryField = ({ name }: { name: string }) => {
  const { textField } = useFilterStyles();
  const [queryValue, setQueryValue] = useQueryState(
    name,
    serializers.numberRange
  );

  const [queryMin = null, queryMax = null] = queryValue ?? [];

  const [[min, max], setValue] = useState([queryMin, queryMax]);
  const apply = () => {
    // Serialize the values
    if (min === null && max === null) {
      return setQueryValue(null);
    }
    setQueryValue([min, max]);
  };
  const clear = () => {
    setValue([null, null]);
    setQueryValue(null);
  };

  const valueStr =
    queryMin === null && queryMax === null
      ? ""
      : [queryMin, queryMax].join(" to ");

  return (
    <FilterButton
      name={name}
      valueStr={valueStr}
      onClear={clear}
      onApply={apply}
      content={
        <Box display="flex" alignItems="baseline">
          <TextField
            className={textField}
            variant="outlined"
            size="small"
            value={min ?? ""}
            onChange={(e) =>
              setValue([
                e.target.value ? parseInt(e.target.value, 10) : null,
                max,
              ])
            }
            helperText="Min"
          />
          <Box mx={2}>to</Box>
          <TextField
            className={textField}
            variant="outlined"
            size="small"
            value={max ?? ""}
            onChange={(e) =>
              setValue([
                min,
                e.target.value ? parseInt(e.target.value, 10) : null,
              ])
            }
            helperText="Max"
          />
        </Box>
      }
    />
  );
};

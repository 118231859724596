/* eslint-disable react/jsx-key */
import { useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import {
  Box,
  Button,
  Card,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import {
  SortQueryField,
  UrlQueryField,
  useEntityTableWhere,
} from "@/components/table";
import { useEntityTableOrderBy } from "@/components/table/useEntityTableOrderBy";
import { useGqlDocumentsContext, useProject } from "@/contexts";
import { useEntityUI } from "@/contexts/entityUI";
import {
  getNamedType,
  GraphQLField,
  isEnumType,
  isObjectType,
  isScalarType,
  isUnionType,
} from "graphql";
import { getArgTypeNameFromInputObject } from "@/helpers/getArgTypeNameFromInputObject";
import { EntityType } from "@/helpers/graphqlDocgen";
import {
  CmsMutationAction,
  useCmsMutationAndInput,
  useCmsQueryAndInput,
} from "@/hooks/cms";
import { useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CellProps, Column, useFilters, useTable } from "react-table";
import { CellRenderer } from "./CellRenderer";
import { PlaceholderRows } from "./PlaceholderRows";
import router from "next/router";
import { getFilters } from "@/helpers/getFilters";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 3),
  },
  filterButtonRow: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexWrap: "wrap",
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  resetButton: {
    marginLeft: theme.spacing(2),
  },
}));

/**
 * Filter out which kind of fields will be rendered in table.
 * Current renders all scalars without id, Relational id, enums and relation objects.
 */
const isFieldVisible = (field: GraphQLField<any, any>) => {
  const type = getNamedType(field.type);

  // We dont show the entity ID, but instead put in at first with action buttons
  if (field.name === "id") return false;

  if (type.name === "ImageUpload") return false;

  // Hide relational object IDs, but render the object with their displayField (if exist, otherwise ID will be displayed)
  if (
    isScalarType(type) &&
    !/ID$/.test(type.name) &&
    !/ReactPageContent/.test(type.name)
  )
    return true;
  if (isObjectType(type) && !/ImageUpload/.test(type.name)) return true;
  if (isUnionType(type)) return true;
  if (isEnumType(type)) return true;
  return false;
};

type EntityTableProps = {
  entity: string;
};

export const EntityTable = ({ entity }: EntityTableProps) => {
  const { entityActionHandler } = useEntityUI();

  // Check if deletion is enabled for this table
  const { mutation: deleteMutation } = useCmsMutationAndInput({
    action: CmsMutationAction.Delete,
    isMany: false,
    entity,
  });
  const enableHardDelete = !!deleteMutation;

  const { query, inputObjectMap } = useCmsQueryAndInput({
    entity,
    isMany: true,
  });
  const orderByFields = inputObjectMap?.orderBy;
  const { project } = useProject();

  const where = useEntityTableWhere(entity);
  const orderBy = useEntityTableOrderBy(entity);

  /* === Begin Getting data rows === */
  const variables: Record<string, any> = { where };
  if (!!orderBy) variables["orderBy"] = orderBy;

  const { data, loading } = useQuery(query!, {
    variables,
    skip: !where,
    fetchPolicy: "no-cache",
  });
  // Get the name of the query from the DocumentNode
  const queryName = getOperationName(query!) ?? `cmsGetMany${entity}`;

  const entityList = data?.[queryName] ?? [];

  /* === End Getting data rows === */

  // Get the entity
  const { typeMap } = useGqlDocumentsContext();
  const entityType = typeMap[`Cms${entity}`];

  const additionalFilters = useMemo(() => getFilters(project, entity), [
    project,
    entity,
  ]);
  const [menuEntity, setMenuEntity] = useState<EntityType | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuEntity(null);
  };

  const columns = useMemo(() => {
    /* === Begin Getting field data === */

    if (!isObjectType(entityType)) {
      console.error(JSON.stringify(entityType), " is not GraphQLObjectType");
      return [];
    }

    // Get the fields of the object
    const fields = Object.values(entityType.getFields()).filter(isFieldVisible);
    /* === End Getting field data === */

    const actionColumn: Column<EntityType> = {
      accessor: "id",
      Header: "Action",
      Cell: (props: CellProps<EntityType>) => (
        <>
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setMenuEntity(props.row.original);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </>
      ),
    };

    const dataColumns = fields.map((field) => ({
      accessor: field.name,
      Cell: CellRenderer,
      field,
      Header: (
        <SortQueryField
          name={field.description ?? field.name}
          orderByFields={orderByFields ?? {}}
        />
      ),
    }));
    return [actionColumn, ...dataColumns];
  }, [entityType, orderByFields]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: entityList,
      manualFilters: true,
    },
    useFilters
  );

  const classes = useStyles();

  return (
    <Card>
      <div className={classes.filterContainer}>
        <Box className={classes.filterButtonRow}>
          {Object.entries(inputObjectMap?.where ?? {})
            .filter(([name]) => name !== "id")
            .map(([name, { type }]) => (
              <UrlQueryField
                key={name}
                name={name}
                argTypeName={type.name}
                inputWhere={inputObjectMap?.where}
              />
            ))}
          {additionalFilters.map((path) => (
            <UrlQueryField
              key={path}
              name={path}
              argTypeName={getArgTypeNameFromInputObject(
                inputObjectMap?.where ?? {},
                path
              )}
            />
          ))}
        </Box>
        <div>
          <Button
            variant="outlined"
            className={classes.resetButton}
            onClick={() =>
              router.push({
                pathname: router.asPath.split("?")[0],
              })
            }
          >
            Reset
          </Button>
        </div>
      </div>

      <TableContainer>
        <Table stickyHeader {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading && <PlaceholderRows />}

            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl && !!menuEntity}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={async () => {
            if (navigator?.clipboard && menuEntity) {
              await navigator.clipboard.writeText(menuEntity.id);
            }
            alert(`Copied ID: ${menuEntity?.id}`);
            handleMenuClose();
          }}
        >
          Copy ID
        </MenuItem>
        <MenuItem
          onClick={() => {
            entityActionHandler?.({
              entity,
              action: CmsMutationAction.Update,
              selectedRow: menuEntity,
            });
            handleMenuClose();
          }}
        >
          Edit
        </MenuItem>
        {enableHardDelete && (
          <MenuItem
            onClick={() => {
              entityActionHandler?.({
                entity,
                action: CmsMutationAction.Delete,
                selectedRow: menuEntity,
              });
              handleMenuClose();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
};

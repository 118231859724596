import { CellPlugin } from "@react-page/editor";
import background, { ModeEnum } from "@react-page/plugins-background";
import divider from "@react-page/plugins-divider";
import html5video from "@react-page/plugins-html5-video";
import { imagePlugin } from "@react-page/plugins-image";
import slate from "@react-page/plugins-slate";
import spacer from "@react-page/plugins-spacer";
import video from "@react-page/plugins-video";

import { cloudinaryImageUploadPlugin } from "./plugins/cloudinaryImageUploadPlugin";
import { colorPickerSlatePlugin } from "./plugins/colorPickerSlatePlugin";
import { fontSizeSlatePlugin } from "./plugins/fontSizeSlatePlugin";
import { sectionTitlePlugin } from "./plugins/sectionTitlePlugin";

export const Plugins = {
  slate: slate((def) => {
    return {
      ...def,
      plugins: {
        ...def.plugins,
        slatePlugins: {
          color: colorPickerSlatePlugin,
          fontSize: fontSizeSlatePlugin,
        },
      },
    };
  }),
  spacer,
  divider,
  image: imagePlugin(),
  video,
  html5video,
  background: background({
    enabledModes:
      ModeEnum.COLOR_MODE_FLAG |
      ModeEnum.IMAGE_MODE_FLAG |
      ModeEnum.GRADIENT_MODE_FLAG,
  }),
  sectionTitle: sectionTitlePlugin,
  imageUpload: cloudinaryImageUploadPlugin,
};

export const addPaddingControlToPlugin = (plugin: CellPlugin<any, any>) => ({
  ...plugin,
  // customize cellStyle
  // you could also wrap `Renderer` in an additional Component
  cellStyle: (data: {
    horizontalPadding: number;
    verticalPadding: number;
  }) => ({
    paddingLeft: data.horizontalPadding,
    paddingRight: data.horizontalPadding,
    paddingTop: data.verticalPadding,
    paddingBottom: data.verticalPadding,
  }),
  controls: [
    {
      title: "Main",
      controls: plugin.controls!,
    },
    {
      title: "Cell style",
      controls: {
        type: "autoform",
        schema: {
          properties: {
            horizontalPadding: { type: "number" },
            verticalPadding: { type: "number" },
          },
        },
      },
    },
  ],
});

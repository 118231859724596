import * as serializers from "@/components/table/UrlQueryField/serializers";
import { useProject } from "@/contexts";
import { getArgTypeNameFromInputObject } from "@/helpers/getArgTypeNameFromInputObject";
import { useCmsQueryAndInput } from "@/hooks/cms";
import { QueryMode } from "@/features/dearshare/generated/generated-hooks";
import { ProjectSlug } from "@/settings/types";
import { endOfDay, startOfDay } from "date-fns";
import { useRouter } from "next/router";

export const useEntityTableWhere = (
  entity: string
): Record<string, any> | null => {
  const router = useRouter();
  const { project } = useProject();
  const queryWithInput = useCmsQueryAndInput({ entity, isMany: true });

  const queryObj = router.query as Record<string, string>;
  const defaultQueries = (project?.defaultQueries ?? {})[entity];
  const query = new URLSearchParams(window.location.search);

  /* if query is empty use default */
  if (!query.toString() && !!defaultQueries) {
    const search = getSearch(query, defaultQueries);

    router.replace({
      pathname: router.asPath,
      search,
    });
    return null;
  }

  return Object.entries(queryObj).reduce((obj, [name, value]) => {
    const paths = name.split(".");

    /* name is a single word e.g. userId */
    if (paths.length === 1) {
      if (!queryWithInput.inputObjectMap?.where?.[name]?.type?.name) {
        return obj;
      }
      const filterQuery = getFilterQuery(
        queryWithInput.inputObjectMap?.where?.[name]?.type?.name,
        value,
        project?.slug !== ProjectSlug.Princess
      );
      if (filterQuery) obj[name] = filterQuery;

      return obj;
    }

    /* name is a path e.g. user.accountName */
    const argTypeName = getArgTypeNameFromInputObject(
      queryWithInput?.inputObjectMap?.where ?? {},
      name
    );

    const filterQuery = getFilterQuery(
      argTypeName,
      value,
      project?.slug !== ProjectSlug.Princess
    );

    if (filterQuery) {
      setFilterQuery(obj, name, filterQuery);
    }

    return obj;
  }, {} as Record<string, any>);
};

/* return something like {user:{accountName: <filterQuery>}} */
const setFilterQuery = (
  obj: Record<string, any>,
  path: string,
  filterQuery: any
) => {
  const keys = path.split(".");
  const lastKey = keys.pop();
  if (!lastKey) return;
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  lastObj[lastKey] = filterQuery;
};

const getFilterQuery = (
  argTypeName: string,
  value: string,
  caseSensitive: boolean
) => {
  const temp = argTypeName?.replace(/nullable/i, "");
  const typeName = temp?.includes("EnumFilter") ? "EnumFilter" : temp;

  switch (typeName) {
    case "EnumFilter":
      return { equals: value };

    case "StringFilter":
      if (caseSensitive) {
        return { contains: value, mode: QueryMode.Insensitive };
      } else {
        return { contains: value };
      }

    case "BoolFilter":
      // Do not handle unknown input, like 'null' or arbitrary string
      if (value === "true" || value === "false") {
        return { equals: value === "true" };
      }
      return;

    case "IntFilter":
    case "FloatFilter":
    case "DecimalFilter":
      const [min = null, max = null] =
        serializers.numberRange.parse(value) ?? [];
      return { gte: min, lte: max };

    case "DateTimeFilter":
      const [start = null, end = null] =
        serializers.dateRange.parse(value) ?? [];

      return {
        gte: start && startOfDay(start).toISOString(),
        lte: end && endOfDay(end).toISOString(),
      };
    default:
      return;
  }
};

const getSearch = (
  query: URLSearchParams,
  defaultQueries: Record<string, string>
) => {
  for (const [key, value] of Object.entries(defaultQueries)) {
    query.set(key, value);
  }
  const search = query.toString();
  return search;
};

import React, { ElementType, CSSProperties, ReactNode } from "react";

export type SectionTitleProps = {
  title: ReactNode;
  color?: string;
  fontSize?: CSSProperties["fontSize"];
  decoratorStyle?: {
    minWidth?: CSSProperties["minWidth"];
    maxWidth?: CSSProperties["maxWidth"];
    height?: CSSProperties["height"];
    color?: CSSProperties["color"];
  };
  component?: ElementType;
  style?: CSSProperties;
};

export const SectionTitle = ({
  title,
  color,
  fontSize = "2.875rem",
  decoratorStyle: {
    minWidth = 50,
    maxWidth = 80,
    height = 2,
    color: decoratorColor = color,
  } = {},
  component = "h3",
  style,
}: SectionTitleProps) => {
  const decoratorStyles = {
    display: "inline-block",
    flex: 1,
    minWidth,
    maxWidth,
    height,
    verticalAlign: "middle",
    backgroundColor: decoratorColor,
  };

  const Component = component;

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 32, // spacing 4

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: 500,
        fontSize,
        color,
      }}
    >
      <div
        style={{
          ...decoratorStyles,
          marginRight: "1.5rem",
        }}
      />
      <Component style={{ ...style }}>{title}</Component>
      <div
        style={{
          ...decoratorStyles,
          marginLeft: "1.5rem",
        }}
      />
    </div>
  );
};

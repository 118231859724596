import { AuthError } from "firebase/auth";

export enum AuthErrorCode {
  ACCOUNT_EXISTS_WITH_DIFF_CREDENTIAL = "auth/account-exists-with-different-credential",
  POPUP_CANCELLED = "auth/cancelled-popup-request",
  POPUP_BLOCKED = "auth/popup-blocked",
  POPUP_CLOSED = "auth/popup-closed-by-user",
  UNAUTHORIZED_DOMAIN = "auth/unauthorized-domain",

  EMAIL_ALREADY_EXISTS = "auth/email-already-exists",
  PHONE_NUMBER_ALREADY_EXISTS = "auth/phone-number-already-exists",
}

export const FirebaseErrorMessages: Record<string, string> = {
  [AuthErrorCode.EMAIL_ALREADY_EXISTS]: "電郵已被使用",
  [AuthErrorCode.PHONE_NUMBER_ALREADY_EXISTS]: "電話已被使用",
};

export function isFirebaseAuthError(error: AuthError) {
  return !!error.code;
}

import { FormControl, FormLabel } from "@material-ui/core";
import { FormFieldProps, Option, useLabelStyles } from "@/components/input";
import { MouseEvent } from "react";
import { useController, useFormContext } from "react-hook-form";
import Select, { components, StylesConfig } from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMove } from "@/helpers/arrayMove";

type SingleSelectInputProps = {
  options: Option[];
  isMulti?: boolean;
  isPrisma?: boolean;
} & FormFieldProps;

const transformToPlainArray = (value: any) => {
  if (Array.isArray(value)) {
    return value.map((v) => v.id);
  }
  return value?.id ?? null;
};

const transformToPrismaArray = (value: any) => {
  if (Array.isArray(value)) {
    if (value.length === 0) return null;
    return value.map((v) => ({ id: v }));
  }
  return { id: value };
};

export const SelectInput = ({
  label,
  options,
  name,
  required = true,
  disabled,
  isMulti = false,
  isPrisma = false,
}: SingleSelectInputProps) => {
  const labelClasses = useLabelStyles();
  const { setValue, control } = useFormContext();
  const {
    field: { value },
  } = useController({ name, control });

  const transformedValue = isPrisma ? transformToPlainArray(value) : value;

  const selectedOption = isMulti
    ? (transformedValue as any[])?.map((v) =>
        options.find((o) => o.value === v)
      )
    : options.find((option) => option.value === transformedValue) ?? null;

  return (
    <FormControl
      fullWidth
      margin="normal"
      required={required}
      disabled={disabled}
    >
      <FormLabel classes={{ root: labelClasses.root }}>{label}</FormLabel>
      <SortableSelect
        isDisabled={disabled}
        isClearable={!required}
        isMulti={isMulti}
        options={options}
        value={selectedOption}
        onChange={(options) => {
          const newValue = isMulti
            ? (options as Option[]).map((option) => option.value)
            : (options as Option)?.value;

          const transformedNewValue = isPrisma
            ? transformToPrismaArray(newValue)
            : newValue;

          setValue(name, transformedNewValue);
        }}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        styles={reactSelectStyles}
        closeMenuOnSelect={!isMulti}
        // react-sortable-hoc props:
        useDragHandle
        axis="xy"
        onSortEnd={({ oldIndex, newIndex }) => {
          const newValue = arrayMove(value, oldIndex, newIndex);
          setValue(name, newValue);
        }}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        // react-select props:
      />
    </FormControl>
  );
};

// Ref: https://react-select.com/advanced#sortable-multiselect
const reactSelectStyles: StylesConfig<any, any> = {
  control: (provided) => ({
    ...provided,
    borderRadius: "none",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid grey",
    boxShadow: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const SortableMultiValue = SortableElement((props: any) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(components.MultiValueLabel);

const SortableSelect = SortableContainer(Select);

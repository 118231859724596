import { FilterButton } from "@/components/table/UrlQueryField";
import * as serializers from "@/components/table/UrlQueryField/serializers";
import { formatDateString } from "@/helpers/formatDateString";

import { useQueryState } from "next-usequerystate";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateRangeQueryField = ({ name }: { name: string }) => {
  const [queryValue, setQueryValue] = useQueryState(
    name,
    serializers.dateRange,
  );

  const [queryStart = null, queryEnd = null] = queryValue ?? [];

  const [[start, end], setValue] = useState([queryStart, queryEnd]);
  const apply = () => {
    if (start === null && end === null) {
      return setQueryValue(null);
    }
    setQueryValue([start, end]);
  };
  const clear = () => {
    setValue([null, null]);
    setQueryValue(null);
  };
  const valueStr =
    queryStart === null && queryEnd === null
      ? ""
      : [queryStart, queryEnd]
          .map((date) => formatDateString(date))
          .join(" to ");

  return (
    <FilterButton
      name={name}
      valueStr={valueStr}
      onClear={clear}
      onApply={apply}
      content={
        <DatePicker
          inline
          selectsRange
          selected={start}
          onChange={(range) => {
            if (Array.isArray(range)) {
              setValue(range);
            }
          }}
          startDate={start}
          endDate={end}
        />
      }
    />
  );
};

import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useQueryParam, QueryParamConfig } from "use-query-params";

export type QueryFilterProps<T, K = T> = {
  name: string;
  queryParam: QueryParamConfig<T, K>;
};

export const useQueryFilter = <T, K = T>({
  name,
  queryParam,
}: QueryFilterProps<T, K>) => {
  const [queryValue] = useQueryParam(name, queryParam);
  const [value, setValue] = useState<K | undefined>(queryValue);
  const { query, push } = useRouter();

  useEffect(() => {
    if (queryValue === undefined) {
      setValue(undefined);
    } else {
      setValue(queryValue);
    }
  }, [queryValue]);

  const setQueryValue = (value: any) => {
    const { [name]: _, ...rest } = query;

    push({
      query: {
        ...rest,
        ...(typeof value !== "undefined" && {
          [name]: typeof value === "boolean" ? +value : value,
        }),
        ...(query.page && name !== "page" && { page: 1 }),
      },
    });
  };

  const apply = () => {
    setQueryValue(value);
  };

  const clear = () => {
    setQueryValue(undefined);
    setValue(undefined);
  };

  return {
    query: {
      queryValue: queryValue as NonNullable<typeof queryValue>,
      setQueryValue,
    },
    field: { value: value as NonNullable<typeof value>, setValue },
    action: { apply, clear },
  };
};

import {
  getNullableType,
  GraphQLInputField,
  isListType,
  isWrappingType,
} from "graphql";
import { InputObjectFieldTypedef } from "./types";

export function getFieldTypedef(
  field: GraphQLInputField
): InputObjectFieldTypedef {
  const { type: fieldType } = field;

  let required = false;
  let isArray = false;
  let arrayRequired = false;

  let currentType = fieldType;

  while (isWrappingType(currentType)) {
    if (isListType(currentType)) {
      isArray = true;
    } else {
      // It must be Nonnull
      if (isListType(getNullableType(currentType))) {
        arrayRequired = true;
      } else {
        required = true;
      }
    }
    currentType = currentType.ofType;
  }

  return {
    name: field.name,
    type: currentType,
    description: field.description ?? "",
    required,
    ...(isArray
      ? { isArray, arrayRequired }
      : { isArray, arrayRequired: undefined }),
  };
}

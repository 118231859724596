import { getPublicConfig } from "@/helpers/getPublicConfig";

import { initializeApp, getApps } from "firebase/app";

import {
  AnalyticsCallOptions,
  getAnalytics,
  logEvent as firebaseLogEvent,
} from "firebase/analytics";
import { getAuth } from "firebase/auth";

const { service } = getPublicConfig();

if (getApps().length <= 0) {
  const app = initializeApp(service.firebase);
  // Check that `window` is in scope for the analytics module!
  if (typeof window !== "undefined") {
    // Enable analytics. https://firebase.google.com/docs/analytics/get-started
    if ("measurementId" in service.firebase) {
      getAnalytics();
    }
  }
}

export const auth = getAuth();

export const logEvent = (
  eventName: string,
  eventParams?: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions
) => {
  if (typeof window === "undefined") return;

  const analytics = getAnalytics();

  firebaseLogEvent(analytics, eventName, eventParams, options);
};

import { FormFieldProps } from "@/components/input";
import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
  SwitchProps,
  Theme,
} from "@material-ui/core";
import { useController, useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
);

export const BooleanInput = ({
  label,
  name,
  disabled,
  ...switchProps
}: Pick<FormFieldProps, "label" | "name"> & SwitchProps) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <FormControlLabel
      disabled={disabled}
      htmlFor={`${name}-input`}
      control={
        <Switch
          id={`${name}-input`}
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...switchProps}
        />
      }
      label={label}
    />
  );
};

import * as Sentry from "@sentry/nextjs";
import { QueryClient, setLogger } from "react-query";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const config = getPublicConfig();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

setLogger({
  // log & warn is not used in query and mutation, so keep it as default
  log: console.log,
  warn: console.warn,

  error: (error) => {
    if (config.isLocal) {
      console.error(error);
    }

    if (config.isPreview || config.isProd) {
      Sentry.captureException(error);
    }
  },
});

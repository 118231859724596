import { makeStyles } from "@material-ui/core";

export const useFilterStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1.5, 3.5),
    fontSize: 14,
    borderRadius: 99,
    "&:hover": {
      boxShadow: "0 0 0 1px #666666",
    },
  },
  focus: {
    backgroundColor: "#e7e7e7",
  },
  active: {
    fontWeight: 600,
    boxShadow: "0 0 0 2px black",
  },
  textField: {
    maxWidth: 150,
  },
  selectField: {
    width: 200,
  },
}));

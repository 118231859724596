import { makeStyles } from "@material-ui/core";

export const useLabelStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  shrink: {
    transform: "none",
  },
});

import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputProps,
} from "@material-ui/core";
import { FormFieldProps, useLabelStyles } from "@/components/input";
import { useController, useFormContext } from "react-hook-form";

type TextInputProps = FormFieldProps & InputProps;

export const TextInput = ({
  label,
  helperText,
  required,
  disabled,
  ...inputProps
}: TextInputProps) => {
  const classes = useLabelStyles();
  const { control } = useFormContext();
  const {
    field: { value, ...field },
  } = useController({ name: inputProps.name, control });

  const inputId = inputProps.id ?? `${inputProps.name ?? label}-input`;
  const helperTextId = `${inputId}-helper-text`;
  return (
    <FormControl
      fullWidth
      margin="normal"
      required={required}
      disabled={disabled}
    >
      <InputLabel htmlFor={inputId} shrink classes={classes}>
        {label}
      </InputLabel>
      <Input
        id={inputId}
        aria-describedby={helperTextId}
        value={value ?? ""}
        {...inputProps}
        {...field}
      />
      <FormHelperText id={helperTextId}>{helperText}</FormHelperText>
    </FormControl>
  );
};

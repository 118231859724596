import { makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles((theme) => ({
  overflowVisible: { overflow: "visible" },
  title: {
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),

    color: theme.palette.grey[500],
  },
}));

/* eslint-disable react/jsx-key */
import { IconButton, Tooltip } from "@material-ui/core";
import OpenInNew from "@material-ui/icons/OpenInNew";

import { useProject } from "@/contexts";
import { format } from "date-fns";
import {
  getNamedType,
  GraphQLField,
  isEnumType,
  isObjectType,
  isScalarType,
  isUnionType,
} from "graphql";
import { EntityType, isEntityType } from "@/helpers/graphqlDocgen";
import { CSSProperties, ReactNode } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CellProps } from "react-table";
import { useRouter } from "next/router";
import { getEntityDisplayString } from "@/helpers/getEntityDisplayString";

/**
 * Render a field of a row object.
 * Wrapping all output in Fragment to avoid type errors
 */
export const CellRenderer = (props: CellProps<EntityType>) => {
  const { project } = useProject();
  const { query } = useRouter();
  const { simplifiedEnum } = project ?? {};

  const {
    row,
    column: { field },
  } = props;

  if (!field) return null;

  const cellContent = row.original[field.name];

  const renderContent = (
    field: GraphQLField<any, any>,
    content: EntityType[keyof EntityType]
  ): ReactNode => {
    if (typeof content === "undefined" || content === null) {
      return "N/A";
    }

    if (Array.isArray(content)) {
      return content
        .map((_content) => renderContent(field, _content))
        .join(", ");
    }

    let type = getNamedType(field.type);

    if (isUnionType(type) && isEntityType(content)) {
      // Match a union type with content type
      const matchedType = type
        .getTypes()
        .find((type) => type.name === content.__typename);
      if (matchedType) {
        // Use the matched type to render content
        type = matchedType;
      }
    }

    if (isScalarType(type) && !isEntityType(content)) {
      switch (type.name) {
        case "Boolean":
          return content ? "True" : "False";
        case "Int":
        case "Float":
          if (typeof content === "number") return content.toString();
          return "N/A";
        case "DateTime":
          return format(new Date(content as string), "yyyy-MM-dd HH:mm");
        case "String":
          return getUrlIcon(content as string);
        default:
          // @todo handle *ID type to link to entity
          return `${content}`;
      }
    }
    if (isEnumType(type)) {
      const style = getStyleForEnumType(`${content}`);
      const getEnumContent = () => {
        return simplifiedEnum?.[query.entity as string]?.includes(field.name)
          ? `${content}`.substring(0, 1)
          : `${content}`;
      };
      return <div style={{ ...style }}>{getEnumContent()}</div>;
    }

    // For object types, we extract the display value using a helper function.
    // We double check it is an object and not an array for extra type safety.
    if (isObjectType(type) && isEntityType(content)) {
      return getEntityDisplayString(project, content);
    }
    return "N/A";
  };

  return <>{renderContent(field, cellContent)}</>;
};

const getUrlIcon = (content: string) => {
  try {
    new URL(content);
    return (
      <Tooltip title={content}>
        <IconButton target="_blank" href={content}>
          <OpenInNew />
        </IconButton>
      </Tooltip>
    );
  } catch (e: any) {
    return `${content}`;
  }
};

/* render enum value with different color */
const green = ["ACTIVE"];
const red = ["BANNED", "REMOVED"];
const getStyleForEnumType = (value: string): CSSProperties => {
  if (green.includes(value)) return { color: "green" };
  if (red.includes(value)) return { color: "red" };
  return {};
};

import { TypedDocumentNode, useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useDialogStyles } from "@/components/form";
import { useProject } from "@/contexts";
import { EntityType, Maybe } from "@/helpers/graphqlDocgen";
import { useNotificationContext } from "@/contexts/notification";
import * as Sentry from "@sentry/nextjs";
import { CmsMutationAction } from "@/hooks/cms";
import { getEntityDisplayString } from "@/helpers/getEntityDisplayString";
export const ConfirmDeleteDialog = ({
  mutation,
  open,
  entity,
  selectedEntityRow,
  onClose,
  onComplete,
}: {
  mutation: TypedDocumentNode<
    {
      [x: string]: Maybe<EntityType>;
    },
    {
      [key: string]: any;
    }
  >;
  open: boolean;
  entity: string;
  onClose?: () => void;
  onComplete?: (result?: any) => Promise<void> | void;
  selectedEntityRow?: EntityType | null;
}) => {
  const { openNotification } = useNotificationContext();
  const classes = useDialogStyles();
  const { project } = useProject();

  const [deleteEntity, { loading }] = useMutation(mutation!, {
    variables: { where: { id: selectedEntityRow?.id } },
    refetchQueries: [`cmsGetMany${entity}`],
  });
  const handleDeleteEntity = async () => {
    try {
      const result = await deleteEntity();

      openNotification({
        message: `Successfully deleted ${displayString}`,
        variant: "success",
      });

      onComplete?.(result);
      onClose?.();
    } catch (e: any) {
      Sentry.withScope((scope) => {
        scope.setTag("entity", entity);
        scope.setTag("action", CmsMutationAction.Delete);
        scope.setTag("entityId", selectedEntityRow?.id);
        scope.setContext("entityRow", selectedEntityRow ?? {});
        Sentry.captureException(e);
      });

      openNotification({
        message: "Error occured, please try again",
        variant: "danger",
      });
    }
  };

  const displayString = getEntityDisplayString(project, selectedEntityRow);
  return (
    <Dialog
      disableEnforceFocus
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6">Confirm delete</Typography>
          {onClose && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {selectedEntityRow ? (
            <Typography variant="body1">
              Are you sure to delete <b>{displayString}</b>? This action cannot
              be undone.
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>

        <DialogActions>
          {loading && <CircularProgress size="1.5rem" />}
          <Button
            variant="text"
            color="primary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteEntity}
            disabled={!selectedEntityRow || loading}
          >
            Delete
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

import {
  BooleanQueryField,
  DateRangeQueryField,
  NumberQueryField,
  StringQueryField,
  EnumQueryField,
} from "@/components/table/UrlQueryField";
import { getEnumValuesFromInputObject } from "@/helpers/getEnumValuesFromInputObject";
import { InputObjectFieldTypedefMap } from "@/helpers/graphqlDocgen/types";

type UrlQueryFieldProps = {
  name: string;
  argTypeName: string;
  inputWhere?: InputObjectFieldTypedefMap;
};

export const UrlQueryField = ({
  argTypeName,
  name,
  inputWhere,
}: UrlQueryFieldProps) => {
  // StringNullableFilter is same as StringFilter in prisma
  const typeName = argTypeName.replace(/nullable/i, "");

  if (typeName === "StringFilter") {
    return <StringQueryField name={name} />;
  }
  if (typeName === "BoolFilter") {
    return <BooleanQueryField name={name} />;
  }
  if (
    typeName === "IntFilter" ||
    typeName === "FloatFilter" ||
    typeName === "DecimalFilter"
  ) {
    return <NumberQueryField name={name} />;
  }
  if (typeName === "DateFilter" || typeName === "DateTimeFilter") {
    return <DateRangeQueryField name={name} />;
  }
  if (typeName.includes("Enum") && !!inputWhere) {
    const options = getEnumValuesFromInputObject(inputWhere, name);

    return <EnumQueryField name={name} options={options} />;
  }

  return null;
};

import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  GraphQLScalarType,
  GraphQLEnumType,
  GraphQLInputObjectType,
} from "graphql";
import {
  BoolNullableFilter,
  DateTimeNullableFilter,
  // DecimalNullableFilter,
  FloatNullableFilter,
  IntNullableFilter,
  StringNullableFilter,
} from "@/features/dearshare/generated/generated-hooks";
import { Scalars as ScalarMap } from "@/features/bora/generated/generated-hooks";

// Helper types to check if type is any
type IsAny<T> = 0 extends 1 & T ? true : never;

// Helper types to filter out any from object
// https://github.com/microsoft/TypeScript/issues/38646#issuecomment-700829042
type FilterOutAny<T> = {
  [K in keyof T as IsAny<T[K]> extends never ? K : never]: T[K];
};

export type OneOrMany<T> = T | T[];

/**
 * Extract keys from codegen Scalars
 */
export type ScalarName = keyof ScalarMap;

/**
 * Proprties with "any" type are filtered out
 */
type ScalarMapWithoutAny = FilterOutAny<ScalarMap>;

/**
 * Union of possible scalar types (without any)
 */
export type Scalars = ScalarMapWithoutAny[keyof ScalarMapWithoutAny];

export type Maybe<T> = T | undefined | null;

/**
 * All entity should have a id field,
 * where other fields can be "at worst" nullable list of nullable scalars or objects.
 * Similar to `[Any]` in graphql language
 */
export type EntityType = {
  __typename?: Maybe<string>;
  id: string;
  [field: string]: Maybe<OneOrMany<Maybe<Scalars | EntityType>>>;
};

/**
 * Type guard to ensure the field value is a non-list object type
 */
export const isEntityType = (obj: unknown): obj is EntityType =>
  typeof obj === "object" && !!obj && !Array.isArray(obj) && "id" in obj;

export type OperationBase = {
  inputObjectMap: InputObjectMap;
};

/**
 * Expected output shape:
 * ```json
 *  "cmsUpdateOneArticle": {
 *    "query": DocumentNode,
 *    "inputObjectMap": {
 *      "where": {
 *        "id": {
 *          "type": "ID",
 *          "required": true,
 *          "isArray": false
 *        }
 *      },
 *      "data": {
 *        "someField": {
 *          "type": "ID",
 *          "required": false,
 *          "isArray": true
 *          "arrayRequired": false
 *        },
 *        ...
 *      }
 *    }
 *  },
 * ```
 */
export type QueryWithInputObjectMap<
  IsMany extends boolean = any
> = OperationBase & {
  // @todo - For queries, there should be a proper shape of return for pagiations, or return a single object
  query: TypedDocumentNode<{
    [x: string]: IsMany extends false
      ? Maybe<EntityType> | undefined
      : Maybe<Array<EntityType>> | undefined; // or Pagination<EntityType>
  }>;
};

export type MutationWithInputObjectMap<
  IsMany extends boolean = any
> = OperationBase & {
  // For mutation, we either create or update the entity, returning a single object
  // @todo handle delete mutation / update/create many mutation
  mutation: TypedDocumentNode<{
    [x: string]: IsMany extends false
      ? Maybe<EntityType>
      : Maybe<Array<Maybe<EntityType>>>;
  }>;
};

export type InputObjectMap = Record<string, InputObjectFieldTypedefMap>;

export type InputObjectFieldTypedefMap = Record<
  string,
  InputObjectFieldTypedef
>;

export type InputObjectFieldTypedef = {
  name: string;
  type: GraphQLScalarType | GraphQLEnumType | GraphQLInputObjectType;
  description?: string;
  required: boolean;
} & (
  | {
      isArray: false;
      arrayRequired: undefined;
    }
  | {
      isArray: true;
      arrayRequired: boolean;
    }
);

/**
 * Prisma CRUD filter inputs
 */
export type WhereManyInput = {
  [field: string]: Maybe<
    | StringNullableFilter
    | IntNullableFilter
    | BoolNullableFilter
    | DateTimeNullableFilter
    // | DecimalNullableFilter
    | FloatNullableFilter
  >;
};

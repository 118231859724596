import { TextField } from "@material-ui/core";
import {
  FilterButton,
  useFilterStyles,
} from "@/components/table/UrlQueryField";
import { useState } from "react";
import { StringParam } from "serialize-query-params";
import { useQueryParam } from "use-query-params";

export const StringQueryField = ({ name }: { name: string }) => {
  const { textField } = useFilterStyles();
  const [queryValue, setQueryValue] = useQueryParam(name, StringParam);
  const [value, setValue] = useState(queryValue);

  const apply = () => setQueryValue(value);
  const clear = () => {
    setValue(null);
    setQueryValue(null);
  };

  return (
    <FilterButton
      name={name}
      valueStr={queryValue}
      onClear={clear}
      onApply={apply}
      content={
        <TextField
          variant="outlined"
          size="small"
          className={textField}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value || null)}
        />
      }
    />
  );
};

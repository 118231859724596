import { GraphQLInputObjectType } from "graphql";
import { getFieldTypedef } from "./getFieldTypedef";
import { InputObjectFieldTypedefMap } from "./types";

export function getInputObjectFieldTypedefs(
  inputObjectType: GraphQLInputObjectType
): InputObjectFieldTypedefMap {
  const fields = inputObjectType.getFields();

  const typedefs: InputObjectFieldTypedefMap = {};

  Object.values(fields).forEach((currentField) => {
    typedefs[currentField.name] = getFieldTypedef(currentField);
  });

  return typedefs;
}

import { InputObjectFieldTypedefMap } from "@/helpers/graphqlDocgen";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { getYupTypeFromFieldTypedef } from "./getYupTypeFromFieldTypedef";
/**
 * Translate InputObjectFieldTypedef to yup schema
 *
 * Example:
 *
 * ```
 * { type: "Int"; required: true; isArray: true, arrayRequired: false } => yup.array().of(yup.number().required())
 * ```
 *
 * ```
 * { type: "String"; required: false; isArray: true, arrayRequired: true } => yup.array().of(yup.string()).required()
 * ```
 */
export const getYupSchemaFromTypedefs = (
  inputTypedefs: InputObjectFieldTypedefMap
): yup.ObjectSchema<any> => {
  const shape = Object.entries(inputTypedefs).reduce(
    (obj, [name, fieldTypedef]) => {
      // FIrst determine the underlying type

      const innerYupType = getYupTypeFromFieldTypedef(fieldTypedef);
      if (!innerYupType) {
        return obj;
      }
      // yup "Required" means non-undefined values
      let fieldYupType = innerYupType;

      if (!fieldTypedef.required) {
        // The type itself is required
        fieldYupType = fieldYupType.nullable();
      }

      // Is array type, wrapping with yup.array().of()
      if (fieldTypedef.isArray) {
        fieldYupType = yup.array().of(fieldYupType);
        if (!fieldTypedef.arrayRequired) {
          // The array is required
          fieldYupType = fieldYupType.nullable();
        }
      }

      obj[name] = fieldYupType;
      return obj;
    },
    {} as ObjectShape
  );
  return yup.object().shape(shape);
};

import {
  FormControl,
  FormLabel,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { ReactPageEditor } from "@/components/react-page";
import { useLabelStyles } from "@/components/input/styles";
import { FormFieldProps } from "@/components/input/types";
import { useController, useFormContext } from "react-hook-form";
import { useProject } from "@/contexts";
import { useRouter } from "next/router";
import { useState } from "react";
type ReactPageInputProps = FormFieldProps;

const useReactPageStyles = makeStyles((theme) => ({
  reactPageContainer: {
    marginRight: theme.spacing(20),
    border: "1px solid lightgrey",
    width: (props: { width: string }) => props.width,
  },
  widthSelector: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: "auto",
  },
}));

type WidthOption = { name: string; width: string };
const widthOptions: WidthOption[] = [
  { name: "iPhone5", width: "320px" },
  { name: "iPhoneX", width: "375px" },
  { name: "iPhone 12 Pro Max", width: "428px" },
  { name: "Page with sidebar", width: "912px" },
  { name: "Tablet", width: "1024px" },
  { name: "MacBook", width: "1440px" },
  { name: "Full HD", width: "1920px" },
  { name: "Full width", width: "100%" },
];

export const ReactPageInput = ({
  name,
  label,
  helperText,
  required,
  disabled, // @todo Investigate how to disable react-page input
}: ReactPageInputProps) => {
  const [width, setWidth] = useState<string>("912px");

  const { control, setValue } = useFormContext();
  const labelClasses = useLabelStyles();
  const {
    field: { value },
  } = useController({ control, name });

  const { project } = useProject();

  const { query } = useRouter();
  const entity = query.entity as string | undefined;

  const reactPageClasses = useReactPageStyles({ width });
  return (
    <>
      <FormControl
        fullWidth
        margin="normal"
        required={required}
        disabled={disabled}
      >
        <FormLabel classes={{ root: labelClasses.root }}>{label}</FormLabel>
        <TextField
          label="Page Width"
          className={reactPageClasses.widthSelector}
          SelectProps={{ native: true }}
          select
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        >
          {widthOptions.map((option, i) => (
            <option key={i} value={option.width}>
              {option.name} ({option.width})
            </option>
          ))}
        </TextField>
        {!entity && (
          <Typography variant="subtitle1" color="textSecondary">
            * Page content edit mode is temporarily disabled. Select a Table in
            the side bar to edit content
          </Typography>
        )}
        <article className={`${reactPageClasses.reactPageContainer} unreset`}>
          <ReactPageEditor
            readOnly={disabled || !entity}
            drawerZIndex={1200}
            plugins={{
              background: true,
              divider: true,
              html5video: false,
              image: false,
              slate: true,
              spacer: true,
              video: true,
              imageUpload: true,
              sectionTitle: true,
            }}
            cloudinaryConfig={project?.cloudinaryConfig}
            value={value}
            onChange={(value) =>
              setValue(name, value, { shouldDirty: true, shouldTouch: true })
            }
            colorPalette={project?.reactPageColorPalette ?? undefined}
          />
        </article>
      </FormControl>
    </>
  );
};

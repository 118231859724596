import { SectionTitle, SectionTitleProps } from "@/components/typography";
import { CellPlugin } from "@react-page/editor";
import React from "react";

import { SliderInput } from "../SliderInput";

export const sectionTitlePlugin: CellPlugin<
  Omit<SectionTitleProps, "fontSize"> & {
    fontSizeValue: number;
    fontSizeUnit: string;
  }
> = {
  Renderer: ({ data: { fontSizeUnit, fontSizeValue, ...data } }) => {
    const fontSize = `${fontSizeValue}${fontSizeUnit}`;

    return <SectionTitle {...data} fontSize={fontSize} />;
  },
  id: "section-title",
  title: "Section Title",
  description: "Section title with horizontal line decorator",
  version: 1,
  //@ts-ignore,
  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: "string",
          default: "(Section Title)",
        },
        color: {
          type: "string",
          default: "#4C3C3C",
          uniforms: {
            allowedValues: ["#4C3C3C", "#B77979"],
          },
        },
        fontSizeValue: {
          type: "number",
          title: "Font size",
          default: 24,
          uniforms: {
            min: 4,
            max: 100,
            step: 0.125,
            //@ts-ignore,
            component: SliderInput,
          },
        },
        fontSizeUnit: {
          type: "string",
          default: "px",
          uniforms: {
            allowedValues: ["px", "rem"],
            checkboxes: true,
          },
        },
      },
    },
  },
};

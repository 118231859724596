import { getInputTypeMeta } from "@/helpers/getInputTypeMeta";
import {
  EntityType,
  InputObjectFieldTypedefMap,
} from "@/helpers/graphqlDocgen";
import { CmsMutationAction } from "@/hooks/cms";
import { ImageUploadType } from "@/features/bora/generated/generated-hooks";
import cloneDeep from "lodash.clonedeep";
export const getDefaultValuesFromTypedefs = (
  inputTypedefs: InputObjectFieldTypedefMap,
  _fromObject?: EntityType
) => {
  const fromObject = cloneDeep(_fromObject);

  return Object.entries(inputTypedefs).reduce((obj, [field, typedef]) => {
    const { entityName, isMulti, action } = getInputTypeMeta(typedef.type.name);
    const prismaRelationObjectField =
      action === CmsMutationAction.Update ? "set" : "connect";

    if (fromObject?.[field]) {
      if (entityName) {
        // Due to limitation in prisma, passing an empty array [] to "set" will throw error
        // Workaround: disconnect all relation without condition, then re-set the items
        //
        const original: any = fromObject?.[field];
        const originalContent = Array.isArray(original)
          ? original.length === 0
            ? null // IMPORTANT: we cannot pass empty array to prisma
            : original.map(({ id }) => ({ id }))
          : original?.id
          ? { id: original.id }
          : null;

        obj[field] = {
          set: originalContent,
          disconnect: originalContent,
        };
        return obj;
      }
      obj[field] = fromObject?.[field];
      return obj;
    }

    if (entityName) {
      obj[field] = { [prismaRelationObjectField]: null };
      return obj;
    }

    if (typedef.isArray) {
      obj[field] = fromObject?.[field] ?? [];
      return obj;
    }

    if (typedef.type.name === "Boolean") {
      obj[field] = false;
      return obj;
    }

    if (typedef.type.name === "String") {
      obj[field] = "";
      return obj;
    }

    if (typedef.type.name === "Int" || typedef.type.name === "Float") {
      obj[field] = 0;
      return obj;
    }

    if (typedef.type.name === "ImageUploadInput") {
      obj[field] = {
        uploadType: ImageUploadType.Url,
        publicId: null,
        cloudName: null,
        url: null,
      };
      return obj;
    }

    obj[field] = null;
    return obj;
  }, {} as Record<string, any>);
};

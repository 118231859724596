import { GraphQLSchema, isObjectType } from "graphql";
import { TypeMap } from "graphql/type/schema";
import {
  MutationWithInputObjectMap,
  QueryWithInputObjectMap,
} from "@/helpers/graphqlDocgen/types";
import { fieldToDocumentWithArgs } from "./fieldToDocument";

/**
 * Regexp Rule to filter types and graphql operations in the returned schema
 */
const FILTER_REGEXP = /^cms/i;

export function generateDocuments(
  schema: GraphQLSchema
): {
  queries: Record<string, QueryWithInputObjectMap>;
  mutations: Record<string, MutationWithInputObjectMap>;
  typeMap: TypeMap;
  entities: { name: string }[];
} {
  // Introspect the schema using normal fetch

  const queryFields = schema.getQueryType()?.getFields() ?? {};
  const mutationFields = schema.getMutationType()?.getFields() ?? {};

  const typeMap = schema.getTypeMap();

  const nonEntityKeywords = ["input", "where", "create", "update", "delete"];
  const nonEntityFilterRegExp = new RegExp(nonEntityKeywords.join("|"), "i"); // "i" for case insensitive

  // Filter out input types, only `cmsXXX` remaining.
  const entities = Object.values(typeMap)
    .filter((graphqlType) => {
      return (
        /^Cms/.test(graphqlType.name) &&
        !nonEntityFilterRegExp.test(graphqlType.name) &&
        isObjectType(graphqlType)
      );
    })
    .map((graphqlType) => {
      const entityName = graphqlType.name.replace(/cms/i, "");
      return {
        name: entityName,
      };
    });

  const queries = Object.entries(queryFields)
    .filter(([name]) => FILTER_REGEXP.test(name))
    .reduce((obj, [name, field]) => {
      return {
        ...obj,
        [name]: fieldToDocumentWithArgs({
          field,
          operation: "query",
        }),
      };
    }, {} as Record<string, QueryWithInputObjectMap>);

  const mutations = Object.entries(mutationFields)
    .filter(([name]) => FILTER_REGEXP.test(name))
    .reduce((obj, [name, field]) => {
      return {
        ...obj,
        [name]: fieldToDocumentWithArgs({
          field,
          operation: "mutation",
        }),
      };
    }, {} as Record<string, MutationWithInputObjectMap>);

  return { queries, mutations, typeMap, entities };
}

import { useMemo } from "react";

import { Badge } from "@/components/tailwind/common/Badge";
import {
  CmsKolJobsProcessingJobReportCountsDocument,
  CmsKolJobsProcessingJobReportCountsQuery,
  CmsKolJobsProcessingJobReportCountsQueryVariables,
} from "@/features/koljobs/generated/generated-hooks";
import { useGqlClient } from "@/hooks/useGqlClient";

export const ProcessingJobReportNotification = () => {
  const { useGqlQuery } = useGqlClient();
  const { data: { cmsKolJobsJobReportCounts } = {} } = useGqlQuery<
    CmsKolJobsProcessingJobReportCountsQuery,
    CmsKolJobsProcessingJobReportCountsQueryVariables
  >({
    gqlDocument: CmsKolJobsProcessingJobReportCountsDocument,
    queryKey: ["CmsKolJobsProcessingJobReportCount"],
  });

  const processingJobReportCount = useMemo(
    () => cmsKolJobsJobReportCounts?.processingJobReports ?? 0,
    [cmsKolJobsJobReportCounts],
  );

  if (!cmsKolJobsJobReportCounts) return null;

  return (
    <Badge
      name={
        processingJobReportCount > 0
          ? cmsKolJobsJobReportCounts.processingJobReports.toString()
          : ""
      }
      variant="inactive"
    />
  );
};

import cookies from "js-cookie";
import { parse } from "cookie";

const TOKEN_NAME = "auth";
const COOKIE_EXPIRY_DAYS = 30;

export const getUserFromCookie = () => {
  const cookie = cookies.get(TOKEN_NAME);
  if (!cookie) {
    return;
  }
  return JSON.parse(cookie);
};

export const setUserCookie = (user: string | object) => {
  cookies.set(TOKEN_NAME, user, {
    // firebase id tokens expire in one hour
    // set cookie expiry to match
    expires: COOKIE_EXPIRY_DAYS,
  });
};

export const removeUserCookie = () => cookies.remove(TOKEN_NAME);

/**
 * @descritions functions for server
 */
export function parseCookies(req: { cookies: any; headers: { cookie: any } }) {
  // For API Routes we don't need to parse the cookies.
  if (req.cookies) return req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = req.headers?.cookie;
  return parse(cookie || "");
}

export function getTokenCookie(req: any) {
  const cookies = parseCookies(req);
  return cookies[TOKEN_NAME];
}

export function getLoginSession(req: any) {
  const token = getTokenCookie(req);
  if (!token) return { token: null, ssrUserId: null };

  const tmp = JSON.parse(token);
  return { token: tmp?.token, ssrUserId: tmp?.id };

  // Validate the expiration date of the session
  // if (Date.now() < expiresAt) {
  //   return session;
  // }
}

import {
  FilterButton,
  useFilterStyles,
} from "@/components/table/UrlQueryField";
import { Option } from "@/components/input";
import { useState } from "react";
import Select from "react-select";
import { StringParam } from "serialize-query-params";
import { useQueryParam } from "use-query-params";

export const EnumQueryField = ({
  name,
  options,
}: {
  name: string;
  options: Option[];
}) => {
  const { selectField } = useFilterStyles();
  const [queryValue, setQueryValue] = useQueryParam(name, StringParam);
  const [value, setValue] = useState(queryValue);

  const apply = () => setQueryValue(value);
  const clear = () => {
    setValue(null);
    setQueryValue(null);
  };
  const getValue = () => options.find((opt) => opt.value === value) ?? null;

  return (
    <FilterButton
      name={name}
      valueStr={queryValue}
      onClear={clear}
      onApply={apply}
      content={
        <Select
          className={selectField}
          value={getValue()}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={options}
          onChange={(selectedOption) => {
            setValue(selectedOption?.value ?? null);
          }}
        />
      }
    />
  );
};

import clsx from "clsx";
import { ComponentProps } from "react";

export const CrownIcon = ({ className, ...props }: ComponentProps<"svg">) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(className, "px-0.5")}
    {...props}
  >
    <path
      d="M1.69415 1.302L5.49915 4.65L8.88915 0.476C8.98408 0.35931 9.10411 0.265539 9.2403 0.20167C9.37649 0.137802 9.52534 0.105481 9.67575 0.107115C9.82617 0.108749 9.97428 0.144295 10.109 0.211107C10.2438 0.27792 10.3618 0.374276 10.4542 0.493L13.6892 4.649L17.6172 1.253C17.7714 1.11991 17.9626 1.03711 18.1652 1.01568C18.3678 0.994254 18.5722 1.03522 18.7508 1.1331C18.9295 1.23098 19.074 1.3811 19.1651 1.56336C19.2561 1.74563 19.2893 1.95136 19.2602 2.153L17.7602 12.5H1.56715L0.0441522 2.2C0.0138994 1.99724 0.0466969 1.79007 0.138103 1.60657C0.229508 1.42307 0.375105 1.27209 0.555173 1.17409C0.735241 1.07609 0.941078 1.0358 1.14481 1.05868C1.34853 1.08156 1.54031 1.1665 1.69415 1.302ZM1.64515 13.5H17.6452V14.5C17.6452 14.7652 17.5398 15.0196 17.3523 15.2071C17.1647 15.3946 16.9104 15.5 16.6452 15.5H2.64515C2.37994 15.5 2.12558 15.3946 1.93805 15.2071C1.75051 15.0196 1.64515 14.7652 1.64515 14.5V13.5Z"
      fill="currentColor"
    />
  </svg>
);

import { User } from "firebase/auth";
import { useMemo } from "react";

import { useAuth } from "@/contexts";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { projects } from "@/settings";
import { ProjectSettings } from "@/settings/types";

const { env } = getPublicConfig();

export const useAvailableProjects = () => {
  const { user } = useAuth();

  const availableProjects = useMemo(() => {
    if (!user) {
      return [];
    }

    return projects.filter((project) => isProjectAvailable(user, project));
  }, [user]);

  // console.log("projects", projects, "availableProjects", availableProjects);

  return availableProjects;
};

const isProjectAvailable = (
  user: Partial<User> | null,
  project: ProjectSettings,
) => {
  if (!user?.email) {
    // No user
    return false;
  }
  const email = user.email;

  if (!project.allowEmailRegex?.[env.vercelEnv]) {
    // Project does not have permission settings
    return false;
  }

  return new RegExp(project.allowEmailRegex[env.vercelEnv]).test(email);
};

export enum FilterTypeEnum {
  AgeFilter = "AgeFilter",
  BooleanFilter = "BooleanFilter",
  DateFilter = "DateFilter",
  EnumFilter = "EnumFilter",
  IntEnumFilter = "IntEnumFilter",
  MultiEnumFilter = "MultiEnumFilter",
  MultiIntEnumFilter = "MultiIntEnumFilter",
  NumberFilter = "NumberFilter",
  ExactNumberFilter = "ExactNumberFilter",
  StringFilter = "StringFilter",
}

export type FilterProps = {
  filterType: FilterTypeEnum;
  options?: FilterFieldProps[];
  hidden?: boolean;
  isLoading?: boolean;
  getQuery?: (rawValue: any) => Record<string, any> | null;
} & FilterFieldProps;

export type FilterFieldProps = {
  label: string;
  name: string;
};

export const ORDER_BY_IDENTIFIER = "orderBy";

import { FormDialogBase } from "@/components/form/FormDialogBase";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

import { useProject } from "@/contexts";
import { useEntityUI } from "@/contexts/entityUI";
import {
  getDefaultValuesFromTypedefs,
  getYupSchemaFromTypedefs,
} from "@/helpers/form";
import { getEntityDisplayString } from "@/helpers/getEntityDisplayString";
import { EntityType, isEntityType } from "@/helpers/graphqlDocgen";
import { CmsMutationAction, useCmsMutationAndInput } from "@/hooks/cms";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { EntityFormFields } from "./EntityFormFields";

type EntityDialogProps = {
  entity: string;
  action: CmsMutationAction;
  onClose?: () => void;
  onComplete?: (result?: any) => Promise<void> | void;
  selectedEntityRow?: EntityType | null;
};

export const EntityDialog = ({
  entity,
  onClose,
  action,
  selectedEntityRow,
  onComplete,
}: EntityDialogProps) => {
  const router = useRouter();
  const open =
    action === CmsMutationAction.Create || action === CmsMutationAction.Update;
  const { project } = useProject();

  const { entityActionHandler } = useEntityUI();

  // Get the name of the query from the DocumentNode

  // Th

  const { mutation, inputObjectMap } = useCmsMutationAndInput({
    entity,
    isMany: false,
    action:
      action === CmsMutationAction.Create
        ? CmsMutationAction.Create
        : CmsMutationAction.Update,
  });

  // The create form is for reads types from args.data

  const [performMutation, { loading: mutationLoading }] = useMutation(
    mutation!,
    {
      refetchQueries: [`cmsGetMany${entity}`],
    }
  );

  const onSubmit = async (data: any) => {
    const variables =
      action === CmsMutationAction.Create
        ? { data }
        : { data, where: { id: selectedEntityRow?.id } };

    try {
      const result = await performMutation({ variables });
      onComplete?.(result);
    } catch (e: any) {
      Sentry.withScope((scope) => {
        scope.setTag("entity", entity);
        scope.setTag("action", action);
        scope.setTag("entityId", selectedEntityRow?.id);
        scope.setContext("entityRow", selectedEntityRow ?? {});
        Sentry.captureException(e);
      });
    }
  };

  // If fetching the row to be updated, do not render

  const titleVerb = action === CmsMutationAction.Create ? "Create" : "Update";
  // For create dialog: "Create Entity"
  // For update dialog: "Update Entity (display name or ID)"

  const entityDisplayString = getEntityDisplayString(
    project,
    selectedEntityRow
  );

  const title = (
    <>
      {`${titleVerb} ${entity} ${
        action === CmsMutationAction.Update && entityDisplayString
          ? `(${entityDisplayString})`
          : ""
      }`}
      {action === CmsMutationAction.Update && router.query.entity !== entity && (
        <IconButton
          onClick={() =>
            entityActionHandler?.({
              action: CmsMutationAction.Update,
              selectedId: selectedEntityRow?.id,
              redirectToTable: true,
              entity,
            })
          }
        >
          <OpenInNew />
        </IconButton>
      )}
    </>
  );

  const props = useMemo(
    () => ({
      resolver: inputObjectMap
        ? yupResolver(getYupSchemaFromTypedefs(inputObjectMap.data))
        : undefined,
      defaultValues: inputObjectMap
        ? getDefaultValuesFromTypedefs(
            inputObjectMap.data,
            isEntityType(selectedEntityRow) ? selectedEntityRow : undefined
          )
        : undefined,
    }),
    [selectedEntityRow, inputObjectMap]
  );

  return (
    <FormDialogBase
      key={entity}
      onSubmit={onSubmit}
      open={open}
      title={title}
      onClose={onClose}
      hookFormProps={props}
      content={
        <EntityFormFields
          loading={mutationLoading}
          entity={entity}
          action={
            action === CmsMutationAction.Create
              ? CmsMutationAction.Create
              : CmsMutationAction.Update
          }
        />
      }
      actions={
        <>
          {mutationLoading && <CircularProgress size="1.5rem" />}
          <Button
            type="submit"
            disabled={mutationLoading}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </>
      }
    />
  );
};

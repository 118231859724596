import { ProjectSettings } from "@/settings/types";
import { EntityType } from "@/helpers/graphqlDocgen";

// Function to render a display string for an object type.
// Used in select labels, table relational objects
export const getEntityDisplayString = (
  project: ProjectSettings | null,
  entityObject?: EntityType | null
) => {
  if (!entityObject || !project) return "";
  // Get the object type from __typename
  const typename = entityObject.__typename;
  if (typename) {
    const displayField = project?.types?.[typename]?.displayField;
    if (displayField) {
      const content = entityObject[displayField];
      if (typeof content === "string" && content !== "") {
        return content;
      }
    }
  }
  // Fall back to ID if fields is null
  return entityObject.id;
};

import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Fragment, useEffect } from "react";

import { useNotificationContext } from "@/contexts/notification";

export type NotificationProps = {
  message?: string;
  open: boolean;
  variant?: "success" | "info" | "warning" | "danger";
};

const classes = {
  variant: {
    success: "bg-green-50 text-green-800",
    info: "bg-blue-50 text-blue-700",
    warning: "bg-yellow-50 text-yellow-800",
    danger: "bg-red-50 text-red-800",
  },
};

export const Notification = ({
  message = "",
  open,
  variant = "info",
}: NotificationProps) => {
  const { closeNotification } = useNotificationContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      closeNotification();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-10000 flex items-end px-4 py-6 sm:p-6"
    >
      <div className="flex w-full items-center space-y-4 sm:items-end">
        <Transition
          show={open}
          as={Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="translate-y-2 opacity-0 sm:-translate-x-2 sm:translate-y-0"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition duration-200 ease-in"
          leaveFrom="translate-y-0 opacity-100 sm:translate-x-0"
          leaveTo="translate-y-2 opacity-0 sm:-translate-x-2 sm:translate-y-0"
        >
          <div
            className={`${classes.variant[variant]} pointer-events-auto w-max overflow-hidden rounded-lg text-sm font-medium shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="shrink-0">
                  {variant === "success" ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-400" />
                  ) : variant === "info" ? (
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" />
                  ) : variant === "warning" ? (
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" />
                  ) : (
                    <XCircleIcon className="h-5 w-5 text-red-400" />
                  )}
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium">{message}</p>
                </div>
                <div className="ml-4 flex shrink-0">
                  <button
                    type="button"
                    className="rounded-md bg-transparent hover:opacity-50 focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => closeNotification()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

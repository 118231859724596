import { formatDateString } from "@/helpers/formatDateString";
import { Serializers } from "next-usequerystate";
import { startOfDay, endOfDay } from "date-fns";

export const numberRange: Serializers<[number | null, number | null]> = {
  parse: (value) =>
    (value
      ?.split(",")
      ?.map((num) => (num === "" ? null : parseInt(num, 10))) ?? [
      null,
      null,
    ]) as [number | null, number | null],

  serialize: ([min, max]) => {
    if (min === null && max === null) {
      return "";
    }
    const minString = min?.toString() ?? "";
    const maxString = max?.toString() ?? "";
    return `${minString},${maxString}`;
  },
};

export const dateRange: Serializers<[Date | null, Date | null]> = {
  parse: (value) => {
    const dateStrArr = value?.split(",");

    if (dateStrArr && dateStrArr.length > 0) {
      return [
        startOfDay(new Date(dateStrArr[0])),
        dateStrArr[1] ? endOfDay(new Date(dateStrArr[1])) : null,
      ] as [Date | null, Date | null];
    }

    return [null, null];
  },
  serialize: ([start, end]) => {
    return `${formatDateString(start)},${formatDateString(end)}`;
  },
};

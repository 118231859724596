import {
  GraphQLInputType,
  isListType,
  isNonNullType,
  ListTypeNode,
  NamedTypeNode,
  TypeNode,
} from "graphql";

/**
 * Recursively Generate Input variable definition
 *
 * Example:
 *
 * Input:  (`[String!]!`)
 * ```ts
 * GraphQLNonNull<GraphQLList<GraphQLNonNull<GraphqlScalarType>>>
 * ```
 *
 * Output:
 * ```ts
 * {
 *    kind: "NonNullType",
 *    type: {
 *      kind: "ListType",
 *      type: {
 *        kind: "NonNullType",
 *        type: {
 *          kind: "NamedType",
 *          name: {
 *            kind: "Name",
 *            value: "String"
 *          }
 *        }
 *      }
 *    }
 *  }
 * ```
 */
export function getVariableDefinitionTypeFromArg(
  graphqlType: GraphQLInputType
): TypeNode {
  if (isListType(graphqlType)) {
    return {
      kind: "ListType",
      type: getVariableDefinitionTypeFromArg(graphqlType.ofType),
    };
  }
  if (isNonNullType(graphqlType)) {
    return {
      kind: "NonNullType",
      type: getVariableDefinitionTypeFromArg(graphqlType.ofType) as
        | NamedTypeNode
        | ListTypeNode,
    };
  }

  return {
    kind: "NamedType",
    name: {
      kind: "Name",
      value: graphqlType.name,
    },
  };
}

import { ClientError } from "graphql-request";

export function getGraphqlErrors(error: ClientError) {
  const graphqlErrors = error?.response?.errors;
  if (Array.isArray(graphqlErrors)) return graphqlErrors;

  return false;
}

export enum ErrorCode {
  INVALID_FIELD_VALUE = "INVALID_FIELD_VALUE",
  ACCESS_DENIED = "ACCESS_DENIED",
  DIRTY_READ = "DIRTY_READ",
}

export const getMutationErrorMessage = (
  message: string,
  extensions: Record<string, string>,
) => {
  if (message === ErrorCode.INVALID_FIELD_VALUE) {
    return `${extensions.field} is invalid`;
  }

  if (message === ErrorCode.ACCESS_DENIED) {
    if (extensions.message === `invalid_account`) {
      return `Your account has no access. Please contact site admin to grant you access.`;
    }
    return `You do not have access to perform this action.`;
  }
};

export const expectedMutationErrors: string[] = Object.values(ErrorCode);

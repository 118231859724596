import { useApolloClient } from "@apollo/client";
import { EntityDialog } from "@/components/form";
import { ConfirmDeleteDialog, EntityActionHandler } from "@/components/table";
import { useProject } from "@/contexts/project";
import { EntityType, isEntityType } from "@/helpers/graphqlDocgen";
import {
  CmsMutationAction,
  useCmsMutationAndInput,
  useCmsQueryAndInput,
} from "@/hooks/cms";
import { useRouter } from "next/router";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const EntityUIContext = createContext<{
  entityActionHandler?: EntityActionHandler;
}>({
  entityActionHandler: async () => {},
});

// A wrapper for all the inner logics
// When there is no object
export const EntityUIProvider: FC = ({ children }) => {
  const { project } = useProject();
  if (
    !project ||
    project?.denyIntrospection ||
    project?.disableDynamicCollections
  ) {
    return (
      <EntityUIContext.Provider value={{}}>{children}</EntityUIContext.Provider>
    );
  }
  return <EntityUIProviderInner>{children}</EntityUIProviderInner>;
};

export const EntityUIProviderInner: FC = ({ children }) => {
  const { push, query: currentQuery, pathname } = useRouter();
  const { project } = useProject();

  // Getting params from query
  const selectedId = currentQuery.rowId as string | null;
  const dialogEntity = (currentQuery.dialogEntity ||
    currentQuery.entity) as string;
  const action = (currentQuery.action ?? null) as CmsMutationAction | null;

  // The selected row. It can be passed with entityActionHandler, or
  // fetched with ID when user enter with raw url
  const [selectedEntityRow, setSelectedEntityRow] = useState<EntityType | null>(
    null
  );

  // Fetching the row with ID.
  // Useful when we only know the ID of the object
  // for example we dont want to load the object in graphql nested query
  // but get the ID only
  const apolloClient = useApolloClient();

  const { query: getOneQuery } = useCmsQueryAndInput({
    entity: dialogEntity ?? "",
    isMany: false,
  });

  useEffect(() => {
    // Only fetch when selectedEntityRow is not set
    if (selectedId && !selectedEntityRow && dialogEntity && getOneQuery) {
      apolloClient
        .query({
          query: getOneQuery,
          variables: { where: { id: selectedId } },
        })
        .then(({ data }) => {
          const row = data[`cmsGetOne${dialogEntity}`];
          if (isEntityType(row)) setSelectedEntityRow(row);
        });
    }
  }, [apolloClient, dialogEntity, getOneQuery, selectedEntityRow, selectedId]);

  // Used to check if delete is available
  const { mutation: deleteMutation } = useCmsMutationAndInput({
    entity: dialogEntity ?? "",
    isMany: false,
    action: CmsMutationAction.Delete,
  });

  // The success callback when dialog performed its action
  const actionCallback = useRef<(result?: any) => Promise<void> | void>();

  const entityActionHandler: EntityActionHandler = async ({
    entity,
    action,
    selectedId,
    selectedRow,
    redirectToTable,
    callback,
  }) => {
    const newQuery: Record<string, any> = {
      action,
    };

    // Setting undefined to an object still result ?rowId= in url
    // need to append to object one by one
    if (selectedId ?? selectedRow?.id) {
      newQuery.rowId = selectedId ?? selectedRow?.id;
    }

    // Open in new tab, we only need the row Id

    if (redirectToTable) {
      window.open(
        `/${project?.slug}/articles/${selectedId}/edit`,
        "_blank",
        "noreferrer noopener"
      );
      return;
    }

    // e.g. Open Article dialog in NavItem or Persons table
    if (currentQuery.entity !== entity) {
      newQuery.dialogEntity = entity;
    }

    push(
      {
        pathname,
        query: { ...currentQuery, ...newQuery },
      },
      undefined,
      { shallow: true } // prevent table rerender due to query change
    );

    // Incoming entity row payload, set to state directly, otherwise
    // it will be fetched in useEffect after query change
    if (selectedRow) {
      setSelectedEntityRow(selectedRow);
    }

    if (callback) actionCallback.current = callback;
  };

  const closeDialog = () => {
    const { action, rowId, dialogEntity, ...newQuery } = currentQuery;
    push(
      {
        pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    ).then(() => {
      actionCallback.current = undefined;
      setSelectedEntityRow(null);
    });
  };

  const canRenderCreateDialog =
    dialogEntity && action === CmsMutationAction.Create;
  const canRenderUpdateDialog =
    dialogEntity && action === CmsMutationAction.Update && selectedEntityRow;
  const canRenderDeleteDialog =
    dialogEntity &&
    action === CmsMutationAction.Delete &&
    selectedEntityRow &&
    deleteMutation;

  return (
    <EntityUIContext.Provider value={{ entityActionHandler }}>
      {(canRenderCreateDialog || canRenderUpdateDialog) && (
        <EntityDialog
          // Ensure the hook-form hook re-render when navigate across page
          selectedEntityRow={selectedEntityRow}
          // key={entity + dialogMode}
          entity={dialogEntity}
          action={action as CmsMutationAction}
          onClose={closeDialog}
          onComplete={(result) => actionCallback.current?.(result)}
        />
      )}

      {!!canRenderDeleteDialog && (
        <ConfirmDeleteDialog
          mutation={deleteMutation!} // TS4.4 should be able to able non-null assertion
          open={action === CmsMutationAction.Delete}
          selectedEntityRow={selectedEntityRow}
          entity={dialogEntity}
          onClose={closeDialog}
          onComplete={(result) => actionCallback.current?.(result)}
        />
      )}

      {children}
    </EntityUIContext.Provider>
  );
};

export const useEntityUI = () => useContext(EntityUIContext);

import { Box, Tooltip, Typography } from "@material-ui/core";
import { Palette } from "@material-ui/icons";
import {
  ColorPicker,
  colorToString,
  stringToColor,
  useUiTranslator,
} from "@react-page/editor";
import { pluginFactories } from "@react-page/plugins-slate";
import { connectField } from "uniforms";

const boraColorPalette = [
  {
    title: "Main",
    colors: [
      "#D2AC3D",
      "#836304",
      "#ECCE75",
      "#202020",
      "#F2F2F2",
      "#2A6BEA",
      "#EFF4FF",
      "#EA910D",
    ],
  },
  {
    title: "Grey",
    colors: ["#333333", "#4F4F4F", "#828282", "#BDBDBD", "#E0E0E0"],
  },
];

const ColorPickerField = connectField<{
  value: string;
  label: string;
  colorPalette?: string[];
  onChange: (v: string) => void;
}>((props) => {
  const { t } = useUiTranslator();

  return (
    <>
      <ColorPicker
        style={{
          marginBottom: 16,
          backgroundColor: "#FFF",
          color: "#888", // primary color
        }}
        color={stringToColor(props.value)}
        buttonContent={t(props.label)}
        onChange={(v: any) => {
          props.onChange(colorToString(v));
        }}
      />

      {boraColorPalette.map(({ title, colors }, i) => {
        return (
          <Box key={i} mb={2}>
            <Typography variant="body2">{title}</Typography>
            <Box display="flex" mt={1}>
              {colors.map((color, idx) => {
                const normalizedColor = colorToString(stringToColor(color));
                const isSelected = normalizedColor === props.value;

                return (
                  <Tooltip key={idx} title={color}>
                    <Box
                      border="1px solid black"
                      height={16}
                      width={16}
                      mr={2}
                      style={{
                        cursor: "pointer",
                        transform: isSelected ? "scale(1.1)" : undefined,
                      }}
                      bgcolor={color}
                      onClick={() => {
                        props.onChange(colorToString(stringToColor(color)));
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
});

export const colorPickerSlatePlugin = pluginFactories.createComponentPlugin<{
  color: string;
}>({
  addHoverButton: true,
  addToolbarButton: true,
  type: "SetColor",
  object: "mark",
  icon: <Palette />,
  label: "Color",
  Component: "span",
  getStyle: ({ color }) => {
    if (color === "rgba(125, 111, 110, 1)") return { color: "#4C3C3C" };
    if (color === "rgba(183, 154, 158, 1)") return { color: "#B77979" };
    return { color };
  },
  controls: {
    type: "autoform",
    schema: {
      type: "object",
      required: ["color"],
      properties: {
        color: {
          uniforms: {
            label: "Select color",
            //@ts-ignore,
            component: ColorPickerField,
          },
          default: "rgb(0,0,0)",
          type: "string",
        },
      },
    },
  },
});

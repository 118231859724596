import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";
import { useLabelStyles } from "@/components/input/styles";
import { FormFieldProps } from "@/components/input/types";
import { useController, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import { formatDateString } from "@/helpers/formatDateString";
import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core";
type DateInputProps = FormFieldProps;

const useDatePickerStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
  },
}));

export const DateInput = (props: DateInputProps) => {
  const { name } = props;
  const datepickerClasses = useDatePickerStyles();

  const { control, setValue } = useFormContext();
  const {
    field: { value },
  } = useController({ name, control });

  // return <DatePicker className="input" onChange={() => {}} />;
  return (
    <DatePicker
      value={value}
      onChange={(date) => {
        if (!date) return;
        if (!Array.isArray(date)) {
          // single date
          setValue(name, formatDateString(date));
        }
        // TODO: date range
      }}
      wrapperClassName={datepickerClasses.wrapper}
      customInput={<CustomInput {...props} />}
    />
  );
};

// Props type any because react-datepicker injects values and onClick
const CustomInput = forwardRef<any, any>((props, ref) => {
  const classes = useLabelStyles();

  const inputId = `${props.name}-date-input`;
  const helperTextId = `${inputId}-helper-text`;
  return (
    <FormControl
      fullWidth
      margin="normal"
      required={props.required}
      disabled={props.disabled}
    >
      <InputLabel htmlFor={inputId} shrink classes={classes}>
        {props.label}
      </InputLabel>
      <Input
        inputRef={ref}
        onClick={props.onClick}
        value={props.value}
        id={inputId}
        aria-describedby={helperTextId}
      />
      <FormHelperText id={helperTextId}>{props.helperText}</FormHelperText>
    </FormControl>
  );
});

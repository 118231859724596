import { format } from "date-fns";

export const formatDateString = (
  date: Date | string | null,
  options?: { withTime?: boolean },
) => {
  if (date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    if (options?.withTime) {
      return format(date, `yyyy-MM-dd HH:mm`);
    }

    return format(date, `yyyy-MM-dd`);
  }

  return "";
};

import {
  CmsGetReportCountDocument,
  CmsGetReportCountQuery,
  CmsGetReportCountQueryVariables,
  ReportStatusEnum,
} from "@/features/princess/generated/generated-hooks";
import { useGqlClient } from "@/hooks/useGqlClient";
import { useMemo } from "react";

export const useReportCount = () => {
  const { useGqlQuery } = useGqlClient();

  const {
    data: {
      totalCount = 0,
      activeReportCount = 0,
      deletedReportCount = 0,
      proceededReportCount = 0,
      invalidReportCount = 0,
    } = {},
  } = useGqlQuery<CmsGetReportCountQuery, CmsGetReportCountQueryVariables>({
    queryKey: ["cmsGetReportCount"],
    gqlDocument: CmsGetReportCountDocument,
    gqlVariables: {
      activeWhere: { status: { equals: ReportStatusEnum.Active } },
      deletedWhere: { status: { equals: ReportStatusEnum.Deleted } },
      proceededWhere: { status: { equals: ReportStatusEnum.Proceeded } },
      invalidWhere: { status: { equals: ReportStatusEnum.Invalid } },
    },
  });

  return useMemo(
    () => ({
      totalCount,
      activeReportCount,
      deletedReportCount,
      proceededReportCount,
      invalidReportCount,
    }),
    [
      totalCount,
      activeReportCount,
      deletedReportCount,
      proceededReportCount,
      invalidReportCount,
    ],
  );
};

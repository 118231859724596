import { Box } from "@material-ui/core";
import {
  BooleanInput,
  DateInput,
  EntitySelectInput,
  ImageUploadInput,
  ReactPageInput,
  SelectInput,
  TextInput,
} from "@/components/input";
import { isEnumType, isScalarType } from "graphql";
import { getInputTypeMeta } from "@/helpers/getInputTypeMeta";
import { InputObjectFieldTypedef } from "@/helpers/graphqlDocgen";
import { CmsMutationAction, useCmsMutationAndInput } from "@/hooks/cms";

type EntityFormFieldsProps = {
  loading?: boolean;
  entity: string;
  action: CmsMutationAction;
};

export const EntityFormFields = ({
  entity,
  action,
  loading = false,
}: EntityFormFieldsProps) => {
  const { inputObjectMap } = useCmsMutationAndInput({
    entity,
    isMany: false,
    action,
  });

  return (
    <div>
      {inputObjectMap &&
        Object.values(inputObjectMap.data).map((typedef) => (
          <Box mb={4} key={typedef.name}>
            <EntityFormField disabled={loading} typedef={typedef} />
          </Box>
        ))}
    </div>
  );
};

type EntityFormFieldProps = {
  typedef: InputObjectFieldTypedef;
  disabled?: boolean;
};
const EntityFormField = ({ typedef, disabled }: EntityFormFieldProps) => {
  const { type, name, description, isArray } = typedef;

  const required = isArray ? typedef.arrayRequired : typedef.required;

  const label = description || name;

  // The create form is for reads types from args.data

  if (isScalarType(type)) {
    const scalarName = type.name;

    if (scalarName === "String") {
      return (
        <TextInput
          disabled={disabled}
          required={required}
          name={name}
          label={label}
        />
      );
    }
    if (scalarName === "Boolean") {
      return <BooleanInput disabled={disabled} name={name} label={label} />;
    }
    if (scalarName === "Int") {
      return <TextInput disabled={disabled} name={name} label={label} />;
    }
    if (scalarName === "Decimal") {
      return <TextInput disabled={disabled} name={name} label={label} />;
    }
    if (scalarName === "Float") {
      return <TextInput disabled={disabled} name={name} label={label} />;
    }
    if (scalarName === "Date") {
      return <DateInput disabled={disabled} name={name} label={label} />;
    }
    if (scalarName === "DateTime") {
      return null;
    }
    if (scalarName === "ReactPageContent") {
      return <ReactPageInput disabled={disabled} name={name} label={label} />;
    }

    // Extract the entity name from the scalar name
    const entityName = scalarName.match(/^Cms(?<entityName>.+)ID$/)?.groups
      ?.entityName;

    if (entityName) {
      return (
        <EntitySelectInput
          disabled={disabled}
          isMulti={isArray}
          name={name}
          label={label}
          required={required}
          entity={entityName}
        />
      );
    }
  }
  if (isEnumType(type)) {
    return (
      <SelectInput
        disabled={disabled}
        required={required}
        name={name}
        options={type
          .getValues()
          .map(({ name, value }) => ({ label: name, value }))}
        label={label}
      />
    );
  }

  if (type.name === "ImageUploadInput") {
    return (
      <ImageUploadInput
        disabled={disabled}
        name={name}
        required={required}
        label={label}
      />
    );
  }

  /**
   * Prisma relation fields. If typename match regexp inside getInputTypeMeta,
   * entityName will be a string
   */
  const { entityName, isMulti, action } = getInputTypeMeta(type.name);

  if (entityName) {
    const prismaRelationObjectField =
      action === CmsMutationAction.Update ? "set" : "connect";
    return (
      <EntitySelectInput
        isPrisma
        disabled={disabled}
        isMulti={isMulti}
        name={`${name}.${prismaRelationObjectField}`}
        label={label}
        required={required}
        entity={entityName}
      />
    );
  }
  return null;
};

import { ProcessingReportNotification } from "@/features/princess/report/components/ProcessingReportNotification";
import { ProjectSettings, ProjectSlug } from "@/settings/types";
import { TicketIcon, UserIcon } from "@heroicons/react/solid";
import CrownIcon from "public/images/icon/crown.svg";
import MessageIcon from "public/images/icon/message.svg";
import { HiCreditCard, HiExclamation } from "react-icons/hi";

export const princess: ProjectSettings = {
  name: "Princess",
  slug: ProjectSlug.Princess,
  iconUrl: "/images/icon/project-icon-princess.png",
  avatarUrl: "/images/icon/project-avatar-princess.png",
  graphqlEndpoint: {
    production: "https://api.princess-dating.com/api/graphql",
    preview: "https://api-preview.princess-dating.com/api/graphql",
    // local: "https://api-preview.princess-dating.com/api/graphql",
    local: "http://localhost:4004/api/graphql",
  },
  allowEmailRegex: {
    production:
      "george@wemakeapp.net|jacky@wemakeapp.net|samuel@wemakeapp.net|andrew@wemakeapp.net|vina@wemakeapp.net|horrychy@gmail.com",
    preview: "@wemakeapp.net",
    local: "@wemakeapp.net",
  },
  denyIntrospection: true,
  sidebarCollections: [
    {
      name: "用戶",
      url: "/table/user",
      icon: UserIcon,
    },
    {
      name: "訊息紀錄",
      url: "/table/message",
      icon: MessageIcon,
    },
    {
      name: "舉報",
      url: "/table/report",
      icon: HiExclamation,
      notification: ProcessingReportNotification,
    },
    {
      name: "訂閱紀錄",
      url: "/table/subscription",
      icon: CrownIcon,
    },
    {
      name: "Ticket",
      url: "/table/ticket",
      icon: TicketIcon,
    },
    {
      name: "帳單紀錄",
      url: "/table/paymentLog",
      icon: HiCreditCard,
    },
  ],
  cloudinaryConfig: {
    cloudName: "dpaqr0jkt",
    uploadPreset: "princess",
  },
};

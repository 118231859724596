/* eslint-disable react/jsx-key */
import { TableCell, TableRow } from "@material-ui/core";
import { LoadingPlaceholder } from "@/components/table";
import "react-datepicker/dist/react-datepicker.css";

export const PlaceholderRows = () => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, i) => (
        <TableRow key={i}>
          <TableCell colSpan={"100%" as any}>
            <LoadingPlaceholder />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

import { ProjectSettings, ProjectSlug } from "@/settings/types";

export const borahair: ProjectSettings = {
  name: "Bora Hair",
  description: "",
  iconUrl: "/images/icon/project-icon-borahair.png",
  avatarUrl: "/images/icon/project-avatar-borahair.png",
  slug: ProjectSlug.BoraHair,
  graphqlEndpoint: {
    production: "https://bora.hair/api/graphql",
    preview: "https://preview.bora.hair/api/graphql",
    // please edit your local `/etc/hosts` file to enable local env
    local: "http://localhost:3039/api/graphql",
  },
  webEndpoint: {
    production: "https://bora.hair",
    preview: "https://preview.bora.hair",
    local: "http://localhost:3039",
  },
  cmsBasePathRegex: {
    production: "cms.bora.hair",
    preview: "cms-preview.bora.hair",
    local: "cms-preview.bora.hair",
    // local: "boracms",
  },

  allowEmailRegex: {
    // preview release of bora hair
    production:
      "jacky@wemakeapp.net|gloria@wemakeapp.net|samuel@wemakeapp.net|csanwel@gmail.com|ytl1017work@gmail.com|workkaroly@gmail.com|admin@bora.hk|chankatrina434@gmail.com|kellyworks2023@gmail.com|nativeaaaa@gmail.com",
    preview: "@wemakeapp.net|csanwel@gmail.com|test@wma.net",
    local: "@wemakeapp.net|csanwel@gmail.com",
  },
  types: {
    CmsArticle: {
      displayField: "title",
    },
    CmsNavItem: {
      displayField: "title",
    },
    CmsTreatment: {
      displayField: "title",
    },
    CmsShop: {
      displayField: "title",
    },
    LinkContent: {
      displayField: "url",
    },
  },
  cloudinaryConfig: {
    cloudName: "dpaqr0jkt",
    uploadPreset: "dawpmzdg",
  },
  reactPageColorPalette: [
    {
      title: "Main",
      colors: ["#2A6BEA", "#EFF4FF", "#EA910D"],
    },
    {
      title: "Grey",
      colors: ["#333333", "#4F4F4F", "#828282", "#BDBDBD", "#E0E0E0"],
    },
  ],
  sidebarCollections: [
    // {
    //   entityName: "TreatmentArticle",
    //   name: "療程文章",
    //   url: "/treatments",
    // },
    {
      entityName: "ArticleCategory",
      name: "護髮學堂",
      url: "/blog",
    },
    // {
    //   name: "其他頁",
    //   url: "/others",
    // },
    {
      name: "選單 (To Be Update)",
      url: "/articleNav",
    },
    {
      entityName: "Article", //this will help filter out dynamic collection
      name: "所有文章",
      url: "/articles",
    },
    {
      entityName: "Faq",
      name: "FAQ",
      url: "/faq",
    },
  ],
};
